import "./FlipCard.scss"
import Card from "./Card"
import { useEffect, useState } from "react"
import { useFamilies } from "../hooks/useFamilies"

const FlipCard = ({ order, reveal, card }) => {
    const [cardClass, setCardClass] = useState(`flip-card flip-card-${order}`)
    const {families} = useFamilies()

    useEffect(() => {
        if(reveal && reveal === order && cardClass === `flip-card flip-card-${order}`) {
            const revealClass = cardClass + ' reveal'
            setCardClass(revealClass)
        }
    }, [reveal, cardClass, order])

    const family = card && families.find(f => f.id === card.family)
    return (<div className={cardClass}>
        <div className="flip-card-inner">
            <div className="card-back">
                <div className="card-rarity"><span className="rarity">{family && family.abbreviation}</span></div>
            </div>
            <Card {...card}></Card>
        </div>
    </div>)
}

export default FlipCard