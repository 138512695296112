import { useEffect, useState } from "react"
import "./Pack.scss"
import FlipCard from "./FlipCard";
import Pack, { usePackCards } from "./Pack";
import { nbCard  } from "../hooks/PackContext";

const PackDraw = (props) => {
    const [classPack, setClassPack] = useState('pack-wrap pack-to-open');
    const [reveal, setReveal] = useState(0)
    const [PackSides, setPackSides] = useState([])

    const { cardsPackProba } = usePackCards(props.cards)
    useEffect(() => {
        if (cardsPackProba.length && props.cards.length) {
            const PackSides = ['left', 'right'].map(direction => (
                <Pack key={direction} classPack={direction} name={props.name} cards={props.cards} />
            ))
            setPackSides(PackSides)
        }
    }, [cardsPackProba, props]);

    useEffect(() => {
        if(reveal === nbCard) {
            props.revealEnded()
            setClassPack(prev => prev + ' pack-open-ended')
        }
    }, [reveal, props])

    const open = () => {
        if (reveal > nbCard) return;
        if (classPack === 'pack-wrap pack-to-open') {
            const classPackOpen = classPack + ' pack-open'
            setClassPack(classPackOpen);
        }
        setReveal(reveal => reveal + 1)
    }

    const openCards = []
    props.cardsDraw.forEach((c, index) => {
        const i = index + 1;
        openCards.push(<FlipCard card={c} key={i} order={i} reveal={reveal} />)
    })

    return (<div className="pack-page">
        <div className={classPack} onClick={() => open()}>
            <div className="open-cards">
                {openCards}
            </div>
            {(cardsPackProba.length && PackSides) || null}
        </div>
    </div>)
}

export default PackDraw