import { useEffect, useRef, useState } from "react";
import Card from "../../components/Card";
import { useCards } from "../../hooks/useCards";
import { useUser } from "../../hooks/useUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faTrash,
    faChess,
    faChessKnight,
    faUpDown,
} from "@fortawesome/free-solid-svg-icons";
import "./Decks.scss";
import { Link } from "react-router-dom";

const nbCardDeck = 5;
const Decks = () => {
    const [deck, setDeckCards] = useState([]);
    const [userCardsUniq, setUserCardsUniq] = useState([]);
    const { user, setDeck, getSubPowerCard } = useUser();
    const userCards = user.cards;
    const { cards } = useCards();
    const refUserDeck = useRef();

    const listCards = [];

    useEffect(() => {
        if (!userCards.length || userCardsUniq.length) return;
        const powerCards = {};
        userCards.forEach((c) => (powerCards[c] = (powerCards[c] || 0) + 1));
        const _userCardsUniq = [];
        Object.keys(powerCards).forEach((cardId) => {
            const card = cards.filter((c) => c.id === cardId);
            _userCardsUniq.push(card[0]);
        });
        setUserCardsUniq(_userCardsUniq);
    }, [cards, userCards, userCardsUniq.length]);

    const addToDeck = (cardId) => {
        const index = deck.indexOf(cardId);
        if (index >= 0) {
            const newDeck = [...deck];
            newDeck.splice(index, 1);
            setDeckCards(newDeck);
        } else {
            if (deck.length >= nbCardDeck) return;
            const newDeck = [...deck, cardId];
            setDeckCards(newDeck);
        }
    };

    useEffect(() => {
        if (user.deck.length) {
            setDeckCards(user.deck);
        }
    }, [user.deck]);

    useEffect(() => {
        if (deck !== user.deck && deck.length === nbCardDeck) {
            setDeck(deck);
        }
    }, [deck, setDeck, user.deck]);

    userCardsUniq.forEach((card) => {
        const power = getSubPowerCard(card.id);
        const posInDeck = deck.findIndex((d) => d === card.id);
        const classListCard =
            posInDeck >= 0 ? "user-card selected-card" : "user-card";
        const selectedCard =
            posInDeck >= 0 ? (
                <>
                    <div className="position-card">{posInDeck + 1}</div>
                </>
            ) : null;
        listCards.push(
            <div
                className={classListCard}
                key={card.id}
                onClick={() => addToDeck(card.id)}
                draggable
            >
                <Card {...card} subpower={power} />
                {selectedCard}
            </div>
        );
    });

    const userDeck = [];
    for (let i = 0; i < nbCardDeck; i++) {
        if (deck[i]) {
            const cardId = deck[i];
            const card = cards.find((c) => c.id === cardId);
            card.subpower = getSubPowerCard(cardId);
            userDeck.push(
                <div key={i} className="deck-card">
                    <button
                        className="btn-remove-deck"
                        onClick={() => addToDeck(cardId)}
                    >
                        <FontAwesomeIcon icon={faTrash} size="xs" />
                    </button>
                    <Card {...card} />
                </div>
            );
        } else {
            userDeck.push(<div key={i} className="deck-card empty"></div>);
        }
    }

    useEffect(() => {
        refUserDeck.current.addEventListener("drop", (e) => console.log(e));
    });

    const onSelect = (e) => {
        const value = e.target.value;
        const _userCardsUniq = [...userCardsUniq];
        switch (value) {
            case "power":
                _userCardsUniq.sort((a, b) => a.power - b.power);
                break;
            case "rarity":
                _userCardsUniq.sort((a, b) => a.rarity - b.rarity);
                break;
            default:
                break;
        }
        setUserCardsUniq(_userCardsUniq);
    };
    const reverseOrder = () => {
        const _userCardsUniq = [...userCardsUniq].reverse();
        setUserCardsUniq(_userCardsUniq);
    };

    const classUserDeck =
        deck.length === nbCardDeck
            ? "user-deck-wrap completed"
            : "user-deck-wrap";
    return (
        <>
            <h1 className="page-title">
                <FontAwesomeIcon icon={faChessKnight} /> Decks (
                {listCards.length} cartes)
            </h1>
            {/* <Link to="/bataille" className={`btn-bataille ${deck.length === nbCardDeck ? 'show' : ''}`}><FontAwesomeIcon icon={faChess} /></Link> */}
            <div className="decks-page">
                {!userCards.length && (
                    <div className="empty-section">
                        Vous n'avez pas de cartes.{" "}
                        <Link to="/packs">
                            Ouvrez des paquets pour en avoir
                        </Link>
                        .
                    </div>
                )}
                {userCards.length ? (
                    <>
                        <div className="filter">
                            <select onChange={onSelect}>
                                <option>Trier par...</option>
                                <option value="power">Puissance</option>
                                <option value="rarity">Rareté</option>
                            </select>
                            <button
                                className="btn-up-down"
                                onClick={reverseOrder}
                            >
                                <FontAwesomeIcon icon={faUpDown} />
                            </button>
                        </div>
                        <div className="user-cards">{listCards}</div>
                    </>
                ) : null}
                <div className={classUserDeck}>
                    <h1>Mon deck</h1>
                    <div className="user-deck" ref={refUserDeck}>
                        {userDeck}
                    </div>
                    <Link to="/bataille" className="btn-bataille">
                        <FontAwesomeIcon icon={faChess} /> Faire une bataille
                    </Link>
                </div>
            </div>
        </>
    );
};

export default Decks;
