import { useEffect, useRef } from "react"
import {useNotificationDispatch} from "../hooks/useNotification"

const Notification = ({ text, id }) => {

    const timerId = useRef(null)

    const notification = useNotificationDispatch()

    const handleRemove = () => {
        notification.remove(id)
    }

    useEffect(() => {
        timerId.current = setTimeout(() => {
            handleRemove()
        }, 4000)

        return () => {
            clearTimeout(timerId.current)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleMouseEnter = () => {
        clearTimeout(timerId.current)
    }

    const handleMouseLeave = () => {
        timerId.current = setTimeout(() => {
            handleRemove()
        }, 4000)
    }

    return <div
        className="notification"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
    >
        {text}
        <button onClick={handleRemove}>✕</button>
    </div>
}

export default Notification