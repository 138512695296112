import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDocs,
    serverTimestamp,
    setDoc,
} from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { db } from "../firebase";
import { useCards } from "./useCards";
import { useUser } from "./useUser";
import { useNotificationDispatch } from "./useNotification";
import { useApplication } from "./ApplicationContext";

export const nbCard = 8;

const INITIAL_STATE = {
    packs: [],
};
export const PackContext = createContext(INITIAL_STATE);

export const PackContextProvider = ({ children }) => {
    const [packs, setPacks] = useState([]);
    const { cards } = useCards();
    const { getSubPowerCard } = useUser();
    const notification = useNotificationDispatch();
    const { updateApplication } = useApplication();

    const getPacks = async () => {
        const packsData = [];
        const q = await getDocs(collection(db, "packs"));
        q.forEach((doc) => {
            const pack = {
                id: doc.id,
                name: doc.data().name,
                visibility: doc.data().visibility,
                cards: JSON.parse(doc.data().cards),
                price: doc.data().price,
                timestamp: doc.data().timestamp,
            };
            packsData.push(pack);
        });
        setPacks(packsData);
    };

    useEffect(() => {
        getPacks();
    }, []);

    const getCardsDraw = (pack) => {
        const cardsPackProba = [];
        pack.cards.forEach((c) => {
            const cardData = cards.find((d) => d.id === c.id);
            const probaReal = c.proba;
            const card = { ...cardData, proba: probaReal };
            // proba
            const probaRound = Math.round(c.proba);
            for (var i = 0; i < probaRound; i++) {
                cardsPackProba.push(card);
            }
        });
        const cardsDraw = [];
        for (var i = 1; i <= nbCard; i++) {
            const card =
                cardsPackProba[
                    Math.floor(Math.random() * cardsPackProba.length)
                ];
            // subpower
            card.subpower = getSubPowerCard(card.id) + 1;
            // ouverture du paquet
            card.isOpening = true;
            // nouveau et doublon
            const newAndDuplicate = cardsDraw.filter(
                (cardDraw) => cardDraw.id === card.id && card.subpower === 1
            );
            const duplicate = cardsDraw.filter(
                (cardDraw) => cardDraw.id === card.id && card.subpower > 1
            );

            if (newAndDuplicate.length) {
                card.subpower += newAndDuplicate.length;
            }
            if (duplicate.length) {
                card.subpower += duplicate.length;
            }

            cardsDraw.push({ ...card });
        }
        return cardsDraw;
    };

    const deletePack = (packId) => {
        deleteDoc(doc(db, "packs", packId)).then(() => {
            notification.add("pack supprimé");
            const newPacks = [...packs].filter((p) => p.id !== packId);
            setPacks(newPacks);
            updateApplication();
        });
    };

    const addPack = (data) => {
        return new Promise((resolve, reject) => {
            data.timeStamp = serverTimestamp();
            addDoc(collection(db, "packs"), data).then(() => {
                notification.add("Nouveau pack ajouté !");
                getPacks();
                resolve();
                updateApplication();
            });
        });
    };

    const updatePack = (packId, data) => {
        return new Promise((resolve, reject) => {
            data.timestamp = serverTimestamp();
            setDoc(doc(db, "packs", packId), data).then(() => {
                notification.add("Pack mis à jour !");
                getPacks();
                resolve();
                updateApplication();
            });
        });
    };

    return (
        <PackContext.Provider
            value={{
                packs,
                getCardsDraw,
                deletePack,
                addPack,
                updatePack,
            }}
        >
            {children}
        </PackContext.Provider>
    );
};
