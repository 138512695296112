import { useContext, useEffect, useState } from "react"
import "./NewPack.scss"
import { useFamilies } from "../../hooks/useFamilies"
import { useNotificationDispatch } from "../../hooks/useNotification"
import { OrderedCards, useCards } from "../../hooks/useCards"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTableCells } from "@fortawesome/free-solid-svg-icons"
import { PackContext } from "../../hooks/PackContext"
import useConfirm from "../../hooks/useConfirm"

const NewPack = (props) => {
    const [pack, setPack] = useState([])
    const [displayCol, setDisplayCol] = useState(2)
    const INIT_DATA = {
        id: null,
        name: '',
        price: 1,
        visibility: true,
        partExtreme: 40,
        partHero: 60
    }
    const [data, setData] = useState(INIT_DATA)

    const { cards } = useCards()
    const { packs, deletePack, addPack, updatePack } = useContext(PackContext)

    const getRarityName = (rarity) => {
        let rarityName = 'rarity'
        const r = parseInt(rarity)
        switch (r) {
            case 1: rarityName = 'héros'; break;
            case 2: rarityName = 'extrême'; break;
            case 3: rarityName = 'sparking'; break;
            case 4: rarityName = 'légende'; break;
            case 5: rarityName = 'ultra'; break;
            default: console.log(rarity); break;
        }
        return rarityName
    }

    const setProbaByRarity = rarity => {
        if (rarity === 5) return 1
        if (rarity === 4) return 2
        if (rarity === 3) return 3
        if (rarity < 3) return 0
    }

    const [cardsTr, setCardsTr] = useState([])

    const { families } = useFamilies()

    useEffect(() => {
        if (!cards.length) return
        const addPackWip = (idCard) => {
            const card = cards.find((c) => c.id === idCard);
            const rarity = parseInt(card.rarity)
            const cardPack = { id: idCard, rarity: rarity }
            let packTemp = [...pack]
            packTemp.push(cardPack)

            setPack(packTemp)
        }


        const removePack = idCard => {
            let filteredPack = pack.filter(p => p.id !== idCard)
            setPack(filteredPack)
        }
        const setProbaPack = (packProba) => {
            if (!packProba.length) return packProba;

            // calcule des proba
            let probaSparkingPlus = 0
            // sparking et +
            packProba.forEach(c => {
                if (c => c.rarity === 5 || c.rarity === 4 || c.rarity === 3) {
                    c.proba = setProbaByRarity(c.rarity)
                }
                return c
            });

            const cardsHight = packProba.filter(c => c.rarity === 5 || c.rarity === 4 || c.rarity === 3)
            cardsHight.forEach(c => {
                probaSparkingPlus += c.proba
            })
            // extreme / héro
            const probaExtremTotal = data.partExtreme - probaSparkingPlus
            const cardsExtrem = packProba.filter(c => c.rarity === 2)
            const cardsHero = packProba.filter(c => c.rarity === 1)
            const probaExtrem = cardsExtrem.length ? (probaExtremTotal / cardsExtrem.length).toFixed(1) : 0
            const probaHero = cardsHero.length ? (data.partHero / cardsHero.length).toFixed(1) : 0
            if (cardsExtrem.length || cardsHero.length) {
                packProba.forEach(c => {
                    if (c.rarity === 2) {
                        c.proba = parseFloat(probaExtrem)
                    }
                    if (c.rarity === 1) {
                        c.proba = parseFloat(probaHero)
                    }
                    return c
                });
            }

            return packProba
        }
        setProbaPack(pack)

        const handleCheckbox = e => {
            const checkbox = e.target.type === 'checkbox' ? e.target : e.target.closest('div').querySelector('.checkbox')
            const idCard = checkbox.id;

            if (!pack.length || !pack.find(p => p.id === idCard)) {
                addPackWip(idCard)
            } else {
                removePack(idCard)
            }
        }

        const cardsTrTemp = []
        cards.forEach(c => {
            const family = families.find(f => f.id === c.family)
            const cardInPack = pack.find(p => p.id === c.id);
            const probaCard = cardInPack ? cardInPack.proba : 0

            cardsTrTemp.push(<div className="line-card" onClick={handleCheckbox} key={c.id} id={`line-${c.id}`}>
                <img alt="" src={c.imageUrl} />
                <span className="card-name">{c.name}</span>
                <span className="w50">{family && family.abbreviation}</span>
                <span className="w50">{c.power}</span>
                <span className="rarity">{getRarityName(c.rarity)}</span>
                <span className="w50">
                    <b className="probability-display">{probaCard}</b> %
                </span>
                <span><input className="checkbox" id={c.id} type="checkbox" /></span>
            </div>)
        })
        setCardsTr(cardsTrTemp)
    }, [pack, data.partExtreme, data.partHero, families, cards])

    useEffect(() => {
        const lines = document.querySelectorAll('.line-card')
        lines.forEach(el => {
            el.classList.remove('active')
            const checkbox = el.querySelector('.checkbox')
            checkbox.checked = false;
        })
        pack.forEach(p => {
            const lineCard = document.getElementById('line-' + p.id);
            const checkboxCard = document.getElementById(p.id);
            checkboxCard.checked = true
            lineCard.classList.add("active");
        })
    }, [pack])

    const notification = useNotificationDispatch()

    const reset = () => {
        setData(INIT_DATA)
        setPack([])
    }
    const minNbCards = 8
    const handleSubmit = e => {
        e.preventDefault()
        const packName = data.name
        const price = data.price
        const visibility = data.visibility

        if (pack.length < minNbCards) {
            notification.add(`Séléctionner au moins ${minNbCards} cartes pour un pack`)
            return
        }
        const packCards = JSON.stringify(pack)

        const dataPack = {
            name: packName,
            cards: packCards,
            price: price || INIT_DATA.price,
            visibility: visibility,
        }

        if(data.id) { // update
            updatePack(data.id, dataPack).then(() => {
                reset()
            })
        } else { // nouveau pack
            addPack(dataPack).then(() => {
                reset()
            })
        }
    }

    const btnColumns = []
    for (let i = 1; i <= 3; i++) {
        btnColumns.push(<button key={i} className={displayCol === i ? 'active' : null} onClick={() => setDisplayCol(i)}>{i}</button>)
    }

    const packsList = []
    packs.forEach(p => {
        packsList.push(<div key={p.id} className={`pack-item ${data.id === p.id ? ' is-editing' : '' }`}>
            <span>{p.name}</span>
            <span>{p.cards.length} cartes</span>
            <span><button onClick={() => editPack(p)}>éditer</button></span>
            <span><button onClick={() => deletePackButton(p.id)}>supprimer</button></span>
        </div>)
    })
    const [ConfirmDelete, confirmDelete] = useConfirm('Supprimer le pack', 'Êtes-vous sûre de vouloir supprimer le pack ?')

    const deletePackButton = async (packID) => {
        const confirm = await confirmDelete()
        if (confirm) deletePack(packID)
    }

    const editPack = (pack) => {
        setData({
            ...data,
            name: pack.name,
            id: pack.id,
            visibility: pack.visibility,
            price: parseInt(pack.price || 1),
            partExtreme: parseInt(pack.partExtreme || INIT_DATA.partExtreme),
            partHero: parseInt(pack.partHero || INIT_DATA.partHero),
        })
        setPack(pack.cards)
    }

    const handleChange = e => {
        const name = e.target.name
        const value = name === 'visibility' ? e.target.checked : e.target.value
        const newData = { ...data, [name]: value }
        setData(newData)
    }

    return (<div>
        <ConfirmDelete />
        <h1 className="page-title">Liste des packs</h1>
        <div className="packs-list">
            {packsList}
        </div>

        <h1 className="page-title">
            {data.id ? 'Édition du pack' : 'Ajouter un Pack'}
            {data.id && <button className="btn-cancel" onClick={() => setData({...data, id: null})}>[annuler]</button>}
        </h1>
        <form className="form-pack" onSubmit={handleSubmit}>
            <p>
                <label>Nom du pack</label>
                <input name="name" value={data.name} onChange={handleChange} required type="text" />
            </p>
            <p>
                <label>Prix</label>
                <input value={data.price} onChange={handleChange} name="price" type="number" />
            </p>
            <p>
                <label>Visible</label>
                <input name="visibility" checked={data.visibility} onChange={handleChange} type="checkbox" />
            </p>
            <p>
                <label>Part extrêmes</label>
                <input value={data.partExtreme} onChange={handleChange} name="part-extreme" type="number" />
            </p>
            <p>
                <label>Part héros</label>
                <input value={data.partHero} onChange={handleChange} name="part-hero" type="number" />
            </p>
            <div className="summary">
                <label>{pack.length} {pack.length > 1 ? 'cartes' : 'carte'}</label>
                <div>
                    <span>{pack.filter(p => p.rarity === 5).length}U</span>
                    <span>{pack.filter(p => p.rarity === 4).length}L</span>
                    <span>{pack.filter(p => p.rarity === 3).length}S</span>
                    <span>{pack.filter(p => p.rarity === 2).length}E</span>
                    <span>{pack.filter(p => p.rarity === 1).length}H</span>
                </div>
            </div>
            <p>
                <button>Envoyer</button>
            </p>
        </form>
        <div className="settings">
            <OrderedCards />
            <div className="settings-columns">
                <FontAwesomeIcon icon={faTableCells} />
                {btnColumns}
            </div>
        </div>
        <div className={`table-pack col-${displayCol}`}>
            {cardsTr}
        </div>
    </div>)
}

export default NewPack