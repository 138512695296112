import { Link, useNavigate, useParams } from "react-router-dom"
import { useUser } from "../../hooks/useUser"
import { useContext, useEffect, useState } from "react"
import { PackContext } from "../../hooks/PackContext"
import PackDraw from "../../components/PackDraw"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChessKnight, faLayerGroup, faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons"
const OpenPack = () => {
    const [packOpen, setPackOpen] = useState(null)
    const [packExist, setPackExist] = useState(null)
    const [pack, setPack] = useState([])
    const [revealOver, setRevealOver] = useState(false)
    const { user, removePack, addCards } = useUser()
    const { idPack } = useParams('idPack')
    const userPacks = user.packs;
    const { packs, getCardsDraw } = useContext(PackContext)
    const navigate = useNavigate()

    const revealEnded = () => {
        setRevealOver(true)
    }

    useEffect(() => {
        if (!userPacks.length || !packs.length) return;
        const packIndex = userPacks.findIndex(up => up === idPack)
        if (packIndex < 0) {
            navigate('/packs');
            return;
        }

        const pack = packs.find(p => p.id === idPack)
        setPack(pack)
        setPackExist(true)
    }, [idPack, navigate, userPacks, packs])

    useEffect(() => {
        if (packExist && pack && !packOpen) {
            removePack(idPack)

            const cardsDraw = getCardsDraw(pack)
            const cardsId = cardsDraw.map(object => object.id);
            addCards(cardsId)

            setPackOpen(<PackDraw {...pack} cardsDraw={cardsDraw} revealEnded={revealEnded} />)
        }
    }, [packExist, removePack, getCardsDraw, addCards, pack, packOpen, idPack])

    const classBtnEnded = revealOver ? "btn-reveal-ended display" : "btn-reveal-ended";
    return (<>
        <h1 className="page-title"><FontAwesomeIcon size="xs" icon={faEnvelopeOpen} /> Ouverture d'un paquet</h1>
        {packOpen}
        <div className={classBtnEnded}>
            {userPacks.length ? <Link to="/packs" className="btn-general">
                <FontAwesomeIcon size="xs" icon={faLayerGroup} /> Ouvrir un autre paquet
                </Link> : null}
            <Link to="/decks" className="btn-general">
                <FontAwesomeIcon size="xs" icon={faChessKnight} /> Constituer un deck
                </Link>
        </div>
    </>)
}

export default OpenPack