import { useEffect, useState } from "react";
import Card from "../../components/Card";
import "./List.scss";
import { Link } from "react-router-dom";
import { OrderedCards, useCards } from "../../hooks/useCards";

function List() {
    const { cards } = useCards();
    const [cardsRender, setCardsRender] = useState([]);

    useEffect(() => {
        const renderCards = () => {
            const cardsRender = [];
            cards.forEach((c) => {
                cardsRender.push(
                    <div className="card-wrap" key={c.id}>
                        <div className="card-actions">
                            <Link to={`/cards/update/${c.id}`}>Modifier</Link>
                        </div>
                        <Card {...c} />
                    </div>
                );
            });
            setCardsRender(cardsRender);
        };

        renderCards();
    }, [cards]);

    return (
        <div>
            <h1 className="page-title">Liste des cartes ({cards.length})</h1>
            <OrderedCards />
            <div className="cards">{cardsRender}</div>
        </div>
    );
}

export default List;
