import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { NotificationProvider } from "./hooks/NotificationContext";
import { AuthContextProvider } from "./hooks/AuthContext";
import { UserContextProvider } from "./hooks/UserContext";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <NotificationProvider>
        <AuthContextProvider>
            <UserContextProvider>
                <App />
            </UserContextProvider>
        </AuthContextProvider>
    </NotificationProvider>
);
