import "./NewFamily.scss";
import { Input, ButtonSubmit } from "../../components/Form";
import { useEffect, useState } from "react";
import { useFamilies } from "../../hooks/useFamilies";

function NewFamily() {
    const [data, setData] = useState({
        name: "",
        abbreviation: "",
        id: null,
    });
    const [editionMode, setEditionMode] = useState(false);
    const [familiesList, setFamiliesList] = useState([]);

    const { families, addFamily, deleteFamily, updateFamily } = useFamilies();

    const editFamily = (family) => {
        setEditionMode(true);
        setData({
            name: family.name,
            abbreviation: family.abbreviation,
            id: family.id,
        });
    };

    useEffect(() => {
        const handleDelete = async (id) => {
            deleteFamily(id);
        };

        const familiesList = [];
        families.forEach((family) => {
            familiesList.push(
                <li key={family.id}>
                    <span>
                        {family.name} / {family.abbreviation}
                    </span>
                    <span>
                        <button
                            onClick={() =>
                                editFamily({ ...family, id: family.id })
                            }
                        >
                            Éditer
                        </button>
                        <button onClick={() => handleDelete(family.id)}>
                            Supprimer
                        </button>
                    </span>
                </li>
            );
        });
        setFamiliesList(familiesList);
    }, [families, deleteFamily]);

    const resetData = () => {
        setData({
            name: "",
            abbreviation: "",
            id: null,
        });
    };
    const handleNew = async (e) => {
        e.preventDefault();

        if (editionMode) {
            const dataFamily = {
                id: data.id,
                name: data.name,
                abbreviation: data.abbreviation,
            };
            updateFamily(dataFamily);

            setEditionMode(false);
            resetData();
        } else {
            const dataFamily = {
                name: data.name,
                abbreviation: data.abbreviation,
            };
            addFamily(dataFamily);
            resetData();
        }
    };

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
    };

    const formConf = {
        name: {
            label: "Nom de la famille",
            name: "name",
            value: data.name,
            onChange: handleInput,
        },
        abbreviation: {
            label: "Abbréviation",
            name: "abbreviation",
            value: data.abbreviation,
            maxlength: 3,
            onChange: handleInput,
        },
    };

    return (
        <div>
            <h1 className="page-title page-title-families">
                <span>
                    {editionMode ? "Éditer une famille" : "Ajouter une famille"}
                </span>
                {editionMode && (
                    <button
                        onClick={() => {
                            setEditionMode(false);
                            resetData();
                        }}
                    >
                        Annuler
                    </button>
                )}
            </h1>
            <form onSubmit={handleNew} className="form-new-family">
                <Input {...formConf.name} />
                <Input {...formConf.abbreviation} />
                <ButtonSubmit />
            </form>
            <h1 className="page-title">Liste des familles</h1>
            <ul className="list-families">{familiesList}</ul>
        </div>
    );
}

export default NewFamily;
