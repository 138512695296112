import { createContext, useContext, useEffect, useReducer } from "react";
import { UserReducer } from "./UserReducer";
import { AuthContext } from "./AuthContext";
import { deleteDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useNotificationDispatch } from "./useNotification";

const INITIAL_STATE = {
    photo: null,
    money: null,
    packs: [],
    createdAt: null,
    pseudo: null,
    uid: null,
    cards: [],
    deck: [],
    wins: 0,
    fights: 0,
    role: 'player'
}

export const UserContext = createContext(INITIAL_STATE)

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(UserReducer, INITIAL_STATE)
    const { uid, deleteUserAuth, status } = useContext(AuthContext)
    const notification = useNotificationDispatch()
    const { logout } = useContext(AuthContext)

    useEffect(() => {
        const getUser = async (uid) => {
            const userRef = doc(db, "users", uid);
            return await getDoc(userRef);
        }

        if (uid && status === 'authenticated') {
            getUser(uid).then(userSnap => {
                const userData = userSnap.data()
                dispatch({
                    type: "LOAD_USER", payload: {
                        photo: userData.photo,
                        money: userData.money,
                        packs: userData.packs,
                        cards: userData.cards,
                        pseudo: userData.pseudo,
                        deck: userData.deck,
                        fights: userData.fights,
                        wins: userData.wins,
                        uid: uid,
                        role: userData.role
                    }
                })
            })
        }
    }, [uid, status])

    const setPhoto = (imageUrl) => {
        const userRef = doc(db, 'users', state.uid)
        updateDoc(userRef, {
            photo: imageUrl
        }).then(() => {
            dispatch({ type: "SET_PHOTO", payload: imageUrl })
            notification.add('photo modifiée')
        })
    }

    const deleteUser = (password) => {
        return new Promise((res, rej) => deleteUserAuth(password)
            .then(() => {
                deleteDoc(doc(db, "users", state.uid))
                res()
            })
            .catch(e => {
                rej()
            })
        )
    }

    const shopPack = (packId, packPrice) => {
        if(state.money <= 0 || (state.money - packPrice < 0)) {
            notification.add(`Vous n'avez pas assez d'argent`)
            return;
        }
        const userRef = doc(db, 'users', state.uid)
        const currentPacks = state.packs ? state.packs : []
        updateDoc(userRef, {
            money: state.money - packPrice,
            packs: [...currentPacks, packId]
        }).then(() => {
            dispatch({
                type: "BUY_PACK", payload: {
                    cost: packPrice,
                    pack: packId
                }
            })
            notification.add('Paquet acheté')
        })
    }

    const removePack = (packId) => {
        const userRef = doc(db, 'users', state.uid)
        const currentPacks = state.packs ? state.packs : []
        if(currentPacks.length) {
            const index = currentPacks.indexOf(packId);
            currentPacks.splice(index, 1);
        }
        updateDoc(userRef, {
            packs: currentPacks
        }).then(() => {
            dispatch({type: "REMOVE_PACK", payload: currentPacks})
        })
    }

    const addCards = (cardsId) => {
        const userRef = doc(db, 'users', state.uid)
        const currentCards = state.cards ? state.cards : []
        updateDoc(userRef, {
            cards: [...currentCards, ...cardsId]
        }).then(() => {
            dispatch({
                type: "ADD_CARDS", payload: cardsId
            })
        })
    }

    const getSubPowerCard = (cardId) => {
        const subpower = state.cards.filter(c => c === cardId)
        return subpower.length
    }

    const cardWithSubpower = (card) => {
        const subpower = getSubPowerCard(card.id)
        card.subpower = subpower
        return card
    }

    const getDeckCards = (cards) => {
        const deck = []
        if(state.deck.length === 5) {
            state.deck.forEach(deckCardId => {
                const card = cardWithSubpower(cards.find(c => c.id === deckCardId))
                deck.push(card)
            })
        }
        return deck
    }

    const setDeck = (deck) => {
        const userRef = doc(db, 'users', state.uid)
        updateDoc(userRef, {
            deck: deck
        }).then(() => {
            dispatch({
                type: "SET_DECK", payload: deck
            })
            notification.add('Deck enregistré')
        })
    }

    const logoutUser = () => {
        dispatch({ type: "LOGOUT", payload: null })
        return logout()
    }

    const gainMoney = money => {
        const userRef = doc(db, 'users', state.uid)
        updateDoc(userRef, {
            money: state.money + money
        }).then(() => {
            dispatch({
                type: "GAIN_MONEY", payload: money
            })
        })
    }

    const updatePseudo = pseudo => {
        const userRef = doc(db, 'users', state.uid)
        updateDoc(userRef, {
            pseudo: pseudo
        }).then(() => {
            dispatch({
                type: "SET_PSEUDO", payload: pseudo
            })
            notification.add('Pseudo modifié')
        })
    }

    const incrementFight = () => {
        const userRef = doc(db, 'users', state.uid)
        updateDoc(userRef, {
            fights: state.fights + 1
        }).then(() => {
            dispatch({
                type: "INCREMENT_FIGHT", payload: 1
            })
        })
    }

    const incrementWin = () => {
        const userRef = doc(db, 'users', state.uid)
        updateDoc(userRef, {
            wins: state.wins + 1
        }).then(() => {
            dispatch({
                type: "INCREMENT_WIN", payload: 1
            })
        })
    }

    return <UserContext.Provider value={{
        user: state,
        setPhoto,
        shopPack,
        logoutUser,
        removePack,
        addCards,
        setDeck,
        getSubPowerCard,
        getDeckCards,
        gainMoney,
        updatePseudo,
        incrementFight,
        incrementWin,
        cardWithSubpower,
        deleteUser
    }}>
        {children}
    </UserContext.Provider>
}