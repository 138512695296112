import { useContext } from "react";
import { NotificationContext, NotificationDispatchContext } from "./NotificationContext"

export const useNotification = () => useContext(NotificationContext)

export const useNotificationDispatch = () => {
    const dispatch = useContext(NotificationDispatchContext)

    const add = (text) => {
        const id = Math.floor(Math.random() * 10000);
        dispatch({ type: "ADD_NOTIFICATION", payload: { id, text } })
    }

    const remove = (id) => {
        dispatch({ type: "DELETE_NOTIFICATION", payload: { id } })
    }

    const actions = { add, remove }

    return actions
}