import { useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../hooks/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faIdCard } from "@fortawesome/free-solid-svg-icons"
import { useNotificationDispatch } from "../../hooks/useNotification"

const Signin = () => {
    const navigate = useNavigate()
    const { signin, status } = useContext(AuthContext)
    const notification = useNotificationDispatch()

    useEffect(() => {
        if(status === 'authenticated') {
            navigate('/shop')
        }
    }, [status, navigate])

    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target);
        const pseudo = (formData.get("pseudo"))
        const email = (formData.get("email"))
        const password = (formData.get("password"))
        signin(email, password, pseudo)
            .catch(e => notification.add(e))
    }

    return (<div className="login-page">
        <h1 className="page-title"><FontAwesomeIcon icon={faIdCard} /> Inscription</h1>
        <form onSubmit={handleSubmit} className="form">
            <p className="form-row"><input required placeholder="pseudo" type="text" name="pseudo" maxLength={16}/></p>
            <p className="form-row"><input required placeholder="e-mail" type="email" name="email" /></p>
            <p className="form-row"><input required minLength={6} placeholder="mot de passe" type="password" name="password" /></p>
            <p className="form-row"><button className="btn-general" type="submit">inscription</button></p>
        </form>
    </div>)
}

export default Signin