import { useContext, useEffect, useState } from "react"
import { useUser } from "../../hooks/useUser"
import { PackContext } from "../../hooks/PackContext"
import Pack from "../../components/Pack"
import { Link, useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelopeOpen, faLayerGroup } from "@fortawesome/free-solid-svg-icons"

const UserPack = () => {
    const navigate = useNavigate()
    const [userPacks, setUserPacks] = useState([])
    const { user } = useUser()
    const { packs } = useContext(PackContext)
    useEffect(() => {
        if (user.packs.length && packs) {
            const userPacks = []
            var countPacks = {};
            user.packs.forEach(function (i) { countPacks[i] = (countPacks[i] || 0) + 1; });
            Object.keys(countPacks).forEach((packId, i) => {
                const numberOfPack = countPacks[packId]
                const pack = packs.find(pack => pack.id === packId)
                userPacks.push(<Link to={'/packs/open/' + packId} key={i} className="user-pack">
                    <Pack key={i} {...pack} classPack="solo" />
                    <span className="btn-open-pack">
                        <FontAwesomeIcon icon={faEnvelopeOpen} /> ouvrir le pack
                        <b>{numberOfPack}</b>
                    </span>
                </Link>)
            })
            setUserPacks(userPacks)
        } else if (!user.packs.length) {

        }
    }, [user.packs, packs, navigate])
    return (<>
        <h1 className="page-title"><FontAwesomeIcon icon={faLayerGroup} /> Mes paquets</h1>
        <div className="user-packs">
            {userPacks}
        </div>
        {!userPacks.length && <div className="empty-section">Vous n'avez pas de paquet. Achetez-en à <Link to="/shop">la boutique</Link>.</div>}
    </>)
}

export default UserPack