import "./Pack.scss"
import { useCards } from "../hooks/useCards";
import { useEffect, useState } from "react";
import { nbCard } from "../hooks/PackContext";
export function usePackCards(cardsPack) {
    const [cardsPackVisual, setCardsPackVisual] = useState([])
    const [cardsPackProba, setCardsPackProba] = useState([])
    const { cards } = useCards()
    useEffect(() => {
        if(!cards.length || !cardsPack.length) return;
    
        cardsPack.sort((a, b) => b.rarity - a.rarity)
    
        const cardsVisual = []
        const cardsProba = []
        cardsPack.forEach(c => {
            const cardData = cards.find(d => d.id === c.id)
            const probaReal = c.proba
            const card = { ...cardData, proba: probaReal }
            // visual
            if (cardsVisual.length < 4) {
                cardsVisual.push(card)
            }
            // proba
            const probaRound = Math.round(c.proba)
            for(var i = 0; i < probaRound; i++) {
                cardsProba.push(card)
            }
        });
        setCardsPackProba(cardsProba)
        setCardsPackVisual(cardsVisual)
    }, [cards, cardsPack])

    return {cardsPackVisual, cardsPackProba}
}

const Pack = (props) => {
    const { cardsPackVisual } = usePackCards(props.cards)
    if(!cardsPackVisual.length) return
    let classPack = 'pack'
    if(props.classPack) classPack += ' pack-' + props.classPack
    return (<div className={classPack}>
        <div className="opener opener-top"></div>
        <div className="opener opener-bot"></div>
        <div className="pack-body">
            <div className="pack-content">
                <h1>{props.name}</h1>
                <p className="pastille"><span><b>{nbCard}</b> cartes</span></p>
                <div className="exclusivite">exclusivité</div>
                <div className="pack-image">
                    <img alt="" className="main-image" src={cardsPackVisual[0].imageUrl} />
                    <div className="side-images">
                        <div className="side-image"><img alt="" src={cardsPackVisual[1].imageUrl} /></div>
                        <div className="side-image"><img alt="" src={cardsPackVisual[2].imageUrl} /></div>
                        <div className="side-image"><img alt="" src={cardsPackVisual[3].imageUrl} /></div>
                    </div>
                </div>
            </div>
        </div>
    </div>)
}

export default Pack