import { useEffect, useId, useState } from "react"
import "./Form.scss"

export function Input(props) {
    const id = useId()
    return(
        <p className="form-row">
            <label htmlFor={id}>{props.label}</label>
            <input required maxLength={props.maxlength} onChange={props.onChange} value={props.value} id={id} name={props.name} type="text" />
        </p>
    )
}

export function InputNumber(props) {
    const id = useId()
    return(
        <p className="form-row">
            <label htmlFor={id}>{props.label}</label>
            <input min="0" onChange={props.onChange} value={props.value} id={id} name={props.name} type="number" />
        </p>
    )
}

export function InputImage(props) {
    const [src, setSrc] = useState('#')
    const [classLabel, setClassLabel] = useState('label-input-image')

    const id = useId()
    const handleFile = (e) => {
        const file = e.target.files[0];
        setSrc(URL.createObjectURL(file))
        setClassLabel('label-input-image with-temp')
        props.onChange(e)
    }

    useEffect(() => {
        if(props.currentImage) {
            setSrc(props.currentImage)
            setClassLabel('label-input-image with-temp')
        }
    }, [props.currentImage])

    return(
        <p className="form-row">
            <label className={classLabel} htmlFor={id}>
                <span className="label">{props.label}</span>
                <span className="placeholder">♠</span>
                <img className="image-temp" alt="temp" src={src} />
                <input accept="image/*" required={props.currentImage ? false : true} onChange={handleFile} value={props.value} id={id} name={props.name} type="file" />
            </label>
        </p>
    )
}

export function Select(props) {
    const options = []
    const id = useId()
    props.options.map(o => {
        const optionsData = {
            key: o.value,
            value: o.value
        }
        return options.push(<option
            {...optionsData}
        >
            {o.label}
        </option>)
    })

    return(
        <p className="form-row">
            <label htmlFor={id}>{props.label}</label>
            <select value={props.value} id={id} name={props.name} onChange={props.onChange}>
                <option value="">Choisir</option>
                {options}
            </select>
        </p>
    )
}

export function ButtonSubmit(props) {
    return(
        <p className="form-row">
            <button className="button-54" type="submit">Envoyer</button>
        </p>
    )
}