import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHome } from "@fortawesome/free-solid-svg-icons"
const Home = () => {
    return (<div>
        <h1 className="page-title"><FontAwesomeIcon icon={faHome} /> Home</h1>
        <div className="welcome">
            Bienvenue sur le jeu la Bataille !<br />
            C'est un projet perso développé avec React.js et Firebase.<br />
            Dans le but de consolider et apprendre davantage React.js.<br />
            J'ai pris du plaisir à développer ce projet surtout les parties animées.<br />
            Les pistes d'améliorations sont infinies et ce ne sont pas les idées qui manquent.<br />
            Voici une première version sans prétention et jouable.<br />
            Coté technique ce que je retiendrai :<br />
            L'importance des Hooks et spécialement des context<br />
            La séparation du dispatch et du state en 2 Provider<br />
            La gestion de l'authentification en général et avec Firebase<br />
            Useeffect et ses dépendances<br />
            React router (useBlocker, useNavigate, Router)<br />
            Gérer un contexte avec un reducer et le localstorage<br />
            Il y a bien sûr un BO pour gérer les packs et les cartes. <br />
            Bon jeu ! <br />
            <i>Janvier 2024, Anthony Ly</i>
        </div>
    </div>)
}

export default Home