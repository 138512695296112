import { createContext, useCallback, useEffect, useReducer } from "react"
import AuthReducer from "./AuthReducer"
import { EmailAuthProvider, createUserWithEmailAndPassword, deleteUser, onAuthStateChanged, reauthenticateWithCredential, signInWithEmailAndPassword, signOut } from "firebase/auth"
import { auth, db } from "../firebase"
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore"

const INITIAL_STATE = {
    uid: null,
    status: "checking"
}

export const AuthContext = createContext(INITIAL_STATE)

export const AuthContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, INITIAL_STATE)

    const validateAuth = useCallback((user) => {
        return new Promise((resolve, reject) => {
            if (user) {
                getUser(user.uid)
                    .then(userExist => {
                        if(userExist) {
                            dispatch({
                                type: "SET_SESSION", payload: {
                                    status: "authenticated",
                                    uid: user.uid,
                                }
                            })
                            resolve()
                        } else {
                            reject()
                        }
                    })
                    .catch(e => reject(e))
            } else {
                reject()
            }
        })
    }, [])

    useEffect(() => {
        const unsub = onAuthStateChanged(auth, user => {
            if (state.status === 'checking') {
                if (user) {
                    validateAuth(user)
                } else {
                    dispatch({
                        type: "SET_SESSION", payload: {
                            status: "no-authenticated",
                            uid: null,
                        }
                    })
                }
            }
            return () => {
                unsub()
            }
        })

    }, [state.status, validateAuth])

    const login = (email, password) => {
        return new Promise((resolve, reject) => {
            signInWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    validateAuth(user).then(() => resolve())
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    reject(errorCode, errorMessage)
                });
        })
    }

    const deleteUserAuth = async(password) => {
        const user = auth.currentUser;
        const email = user.email
        const credential = EmailAuthProvider.credential(
            email,
            password
        )
        return reauthenticateWithCredential(
            auth.currentUser, 
            credential
        ).then(() => {
            deleteUser(user)
        })
        //return 
    }

    const logout = () => {
        return new Promise((resolve, reject) => {
            signOut(auth)
                .then(() => {
                    dispatch({
                        type: "SET_SESSION", payload: {
                            status: "no-authenticated",
                            uid: null,
                        }
                    });
                    resolve(true)
                    
                })
                .catch((error) => {
                    console.log(error)
                });
        })
    }

    const getUser = async (uid) => {
        const docRef = doc(db, "users", uid);
        const docSnap = await getDoc(docRef);
        return docSnap.exists()
    }

    const signin = (email, password, pseudo) => {
        const addUser = async (data, uid) => {
            return await setDoc(doc(db, 'users', uid), data)
        }

        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(auth, email, password)
                .then((userCredential) => {
                    const user = userCredential.user;
                    const uid = user.uid

                    const dataUser = {
                        photo: '/profile.jpeg',
                        pseudo: pseudo,
                        packs: [],
                        money: 10,
                        createdAt: serverTimestamp(),
                        deck: [],
                        fights: 0,
                        wins: 0,
                        cards: [],
                        role: 'player'
                    }
                    addUser(dataUser, uid).then(() => {
                        validateAuth(user).then(() => resolve())
                    });
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    reject(errorCode, errorMessage)
                });
        })
    }

    return (<AuthContext.Provider value={{
        status: state.status,
        uid: state.uid,
        user: state.user,
        login,
        logout,
        signin,
        deleteUserAuth
    }}>
        {children}
    </AuthContext.Provider>)
}