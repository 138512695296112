import { useEffect, useState } from "react";
import { useFamilies } from "../hooks/useFamilies";
import "./Card.scss";

function Card(props) {
    // subpowers
    const subpowers = [];
    for (let i = 1; i <= 7; i++) {
        const subpower = i === 7 ? "★" : i;
        let isActive =
            props.subpower === i || (i === 7 && props.subpower >= 7)
                ? "subpower-active"
                : null;
        subpowers.push(
            <li key={i} className={isActive}>
                {subpower}
            </li>
        );
    }

    // family
    const [family, setFamily] = useState("?");
    const { families } = useFamilies();
    useEffect(() => {
        if (families) {
            var familyCard = families.find((f) => f.id === props.family);
            familyCard && setFamily(familyCard.abbreviation);
        }
    }, [families, props.family]);

    const attrCard = {
        id: props.id,
        className: `card card-${props.rarity} ${
            props.isOpening ? " card-opening" : null
        }`,
        title: props.proba ? `${props.proba} %` : null,
    };
    return (
        <div {...attrCard}>
            {props.subpower === 1 && props.isOpening && (
                <span className="card-new">New</span>
            )}
            <div className="card-content">
                <div className="card-rarity">
                    <span className="rarity">{family}</span>
                </div>
                <div className="card-image">
                    <img alt={props.name} src={props.imageUrl} />
                </div>
                <div className="card-bottom">
                    <div className="card-character-name">{props.name}</div>
                    <div className="card-attack-name">{props.attack}</div>
                    <span>PUISSANCE</span>
                    <div className="card-power">{props.power}</div>
                    <div className="card-subpower">
                        <ul>{subpowers}</ul>
                    </div>
                </div>
            </div>
        </div>
    );
}

Card.defaultProps = {
    rarity: "ultra",
    family: "dbz",
    name: "name",
    attack: "attack name",
    imageUrl:
        "https://fastly.picsum.photos/id/530/200/300.jpg?hmac=pl2pzOmYOiMa6E_Ddf_SFQVGjDvmZ1xgj-JznVHuUsg",
    power: 93,
    subpower: 1,
};

export default Card;
