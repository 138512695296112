import Notification from "./Notification"
import "./Notification.scss"

const NotificationsContainer = ({ notifications }) => {
    return (
        <div className="notifications-container">
            {notifications.map((notification) => (
                <Notification key={notification.id} {...notification} />
            ))}
        </div>
    )
}

export default NotificationsContainer