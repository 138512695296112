import { useEffect, useRef, useState } from "react";
import { useCards } from "../../hooks/useCards";
import { useUser } from "../../hooks/useUser";
import useConfirm from "../../hooks/useConfirm";
import "./Bataille.scss";
import Card from "../../components/Card";
import FlipCard from "../../components/FlipCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCrown,
    faCoins,
    faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useBlocker, useNavigate } from "react-router-dom";
import { useNotificationDispatch } from "../../hooks/useNotification";
import confetti from "canvas-confetti";

const nbCard = 5;
const moneyGain = 1;
const Bataille = () => {
    const [playerB, setPlayerB] = useState({});
    const [cardsA, setCardsA] = useState([]);
    const [cardsB, setCardsB] = useState([]);
    const [battleFieldA, setBattleFieldA] = useState();
    const [battleFieldB, setBattleFieldB] = useState();
    const [fighting, setFighting] = useState(false);
    const [history, setHistory] = useState([]);
    const [scoreA, setScoreA] = useState(0);
    const [scoreB, setScoreB] = useState(0);
    const [isOver, setIsOver] = useState(false);
    const [turn, setTurn] = useState(0);
    const [isLeaving, setIsLeaving] = useState(false);
    const [classBataillePage, setClassBataillePage] = useState("bataille-page");
    const [isStart, setIsStart] = useState(false);
    const [toggleHistory, setToggleHistory] = useState(false);
    const handBRef = useRef(null);
    const battleARef = useRef(null);
    const battleBRef = useRef(null);

    const { user, gainMoney, incrementFight, incrementWin } = useUser();
    const { cards } = useCards();
    const { getDeckCards } = useUser();
    const [ModalLeave, confirmLeave] = useConfirm(
        "Quitter la bataille",
        "Vous êtes sur le point de quitter une bataille en cours. Êtes-vous sûr de vouloir abandonner ?"
    );

    const navigate = useNavigate();
    const notification = useNotificationDispatch();

    useEffect(() => {
        if (!user.deck.length && user.uid) {
            navigate("/decks");
            notification.add(
                "Vous devez avoir un deck pour vous lancer dans une bataille."
            );
        }
    }, [navigate, user.deck.length, notification, user.uid]);

    useEffect(() => {
        if (isStart || !user.uid || !user.deck.length) return;
        setIsStart(true);
        incrementFight();
    }, [isStart, incrementFight, user.uid, user.deck.length]);

    useEffect(() => {
        if (isOver || !cards) return;
        const randCard = cards[Math.floor(Math.random() * cards.length)];
        setPlayerB({
            pseudo: randCard.name,
            photo: randCard.imageUrl,
        });
    }, [cards, isOver]);
    // init deck
    useEffect(() => {
        if (isOver || !cards) return;
        if (cards) {
            // deck A
            const cardsA = getDeckCards([...cards]);
            // deck B
            let allCards = [...cards];
            const cardsB = [];
            for (var i = 0; i < nbCard; i++) {
                const cB =
                    allCards[Math.floor(Math.random() * allCards.length)];
                allCards = allCards.filter((c) => c !== cB);
                cB.subpower = Math.floor(Math.random() * 6 + 1);
                cardsB.push(cB);
            }

            setCardsA(cardsA);
            setCardsB(cardsB);
        }
    }, [getDeckCards, cards, isOver]);

    const getWinner = (_powerA, _subPowerA, _powerB, _subPowerB) => {
        const powerA = parseInt(_powerA);
        const powerB = parseInt(_powerB);
        const subPowerA = parseInt(_subPowerA);
        const subPowerB = parseInt(_subPowerB);

        let winner = null;
        if (powerA === powerB && subPowerA === subPowerB) {
            winner = "X";
        } else if (powerA === powerB && subPowerA !== subPowerB) {
            if (subPowerA > subPowerB) {
                winner = "A";
            } else {
                winner = "B";
            }
        } else {
            winner = powerA > powerB ? "A" : "B";
        }
        return winner;
    };

    useEffect(() => {
        const animResult = (win, delta) => {
            let anim = win ? "winning" : "losing";
            if (delta > 30) {
                return anim + "-3";
            }
            if (delta > 10) {
                return anim + "-2";
            }
            return anim;
        };

        let timerBattle = null;
        if (fighting && battleFieldA && battleFieldB) {
            const result = history[history.length - 1];
            const powerA = parseInt(result.cardA.power);
            const subPowerA = parseInt(result.cardA.subpower);
            const powerB = parseInt(result.cardB.power);
            const subPowerB = parseInt(result.cardB.subpower);

            // winner
            let winner = getWinner(powerA, subPowerA, powerB, subPowerB);

            // animation
            const deltaPower = Math.abs(powerA - powerB);
            let animA = null;
            let animB = null;
            if (winner === "X") {
                animA = "equality";
                animB = "equality";
            } else {
                animA = animResult(winner === "A", deltaPower);
                animB = animResult(winner === "B", deltaPower);
            }
            battleARef.current.classList.add(animA);
            battleBRef.current.classList.add(animB);

            timerBattle = setTimeout(() => {
                setBattleFieldB(null);
                setBattleFieldA(null);

                let newScoreA = null;
                let newScoreB = null;
                if (winner === "X") {
                    newScoreA = scoreA + 1;
                    newScoreB = scoreB + 1;
                } else {
                    newScoreA = winner === "A" ? scoreA + 1 : scoreA;
                    newScoreB = winner === "B" ? scoreB + 1 : scoreB;
                }

                setScoreA(newScoreA);
                setScoreB(newScoreB);

                setTurn((t) => t + 1);
                setFighting(false);
            }, 2000);

            return () => {
                if (timerBattle) clearTimeout(timerBattle);
            };
        }
    }, [
        fighting,
        battleFieldA,
        battleFieldB,
        scoreA,
        scoreB,
        cardsA.length,
        cardsB.length,
        history,
    ]);

    useEffect(() => {
        if (!battleFieldA || !battleFieldB || !fighting) {
            battleBRef.current.classList.remove(
                "winning",
                "winning-2",
                "winning-3",
                "losing",
                "losing-2",
                "losing-3",
                "equality"
            );
            battleARef.current.classList.remove(
                "winning",
                "winning-2",
                "winning-3",
                "losing",
                "losing-2",
                "losing-3",
                "equality"
            );
        }
    }, [battleFieldA, battleFieldB, fighting]);

    let isMoving = false;
    const handleClick = (e) => {
        if (isMoving || fighting) return;

        isMoving = true;
        const cardHandA = e.currentTarget;
        cardHandA.classList.add("card-hand-moving");
        const idA = cardHandA.dataset.id;

        // hand B
        const handBChildrens = handBRef.current.children;
        const randomIndexB = Math.floor(Math.random() * handBChildrens.length);
        const cardHandB = handBChildrens[randomIndexB];
        cardHandB.querySelector(".flip-card").classList.add("reveal");
        const idB = cardHandB.dataset.id;
        cardHandB.classList.add("card-hand-moving");

        setTimeout(() => {
            // moving
            const cardA = cardsA.find((c) => c.id === idA);
            const newCardsA = [...cardsA].filter((c) => c.id !== idA);
            setCardsA(newCardsA);
            setBattleFieldA(<Card {...cardA} />);

            const cardB = cardsB.find((c) => c.id === idB);
            const newCardsB = [...cardsB].filter((c) => c.id !== idB);
            setCardsB(newCardsB);
            setBattleFieldB(<Card {...cardB} />);

            const result = {
                cardA: cardA,
                cardB: cardB,
            };
            setHistory([...history, result]);

            isMoving = false;
            setFighting(true);
        }, 900);
    };

    const handA = [];
    const handB = [];
    if (cardsA.length) {
        cardsA.forEach((c) => {
            handA.push(
                <div
                    key={c.id + Math.floor(Math.random() * 1000)}
                    data-id={c.id}
                    onClick={handleClick}
                    className="card-hand"
                >
                    <Card {...c} />
                </div>
            );
        });
        cardsB.forEach((c, i) => {
            handB.push(
                <div
                    key={c.id + Math.floor(Math.random() * 1000)}
                    data-id={c.id}
                    className="card-hand"
                >
                    <FlipCard card={c} order={i} />
                </div>
            );
        });
    }

    // start
    useEffect(() => {
        const startTimer = setTimeout(
            () => setClassBataillePage((prev) => prev + " start"),
            2000
        );

        return () => clearTimeout(startTimer);
    }, []);

    useEffect(() => {
        if (turn === nbCard && !isOver) {
            let winner = null;
            if (scoreA === scoreB) {
                winner = "x";
            } else {
                winner = scoreA > scoreB ? "a" : "b";
            }

            setClassBataillePage(
                (prev) => prev + " end player-winner-" + winner
            );
            setIsOver(true);

            if (winner === "a") {
                gainMoney(moneyGain);
                incrementWin();
                confetti();
            }
        }
    }, [scoreA, scoreB, isOver, gainMoney, turn, incrementWin]);

    const blocker = useBlocker(
        ({ currentLocation, nextLocation }) =>
            !isOver && currentLocation.pathname !== nextLocation.pathname
    );

    useEffect(() => {
        if (blocker.state === "blocked" && !isLeaving) {
            setIsLeaving(true);
            confirmLeave().then((r) => {
                if (r) blocker.proceed();
                else blocker.reset();
                setIsLeaving(false);
            });
        }
    }, [blocker, isLeaving, confirmLeave]);

    const renderHistory = () => {
        const historyBattles = [];
        let scoreHistoryA = 0;
        let scoreHistoryB = 0;
        history.forEach((h, i) => {
            const winner = getWinner(
                h.cardA.power,
                h.cardA.subpower,
                h.cardB.power,
                h.cardB.subpower
            );
            const classA =
                winner === "A" ? "history-card" : "history-card lose";
            const classB =
                winner === "B" ? "history-card" : "history-card lose";
            scoreHistoryA += winner !== "B" ? 1 : 0;
            scoreHistoryB += winner !== "A" ? 1 : 0;
            historyBattles.push(
                <li key={i} className="history-line">
                    <span
                        className={classA}
                        title={`${h.cardA.power} (${h.cardA.subpower})`}
                    >
                        <img alt="" src={h.cardA.imageUrl} />
                    </span>
                    <span className="history-score">
                        <b>{scoreHistoryA}</b>
                        <b>{scoreHistoryB}</b>
                    </span>
                    <span
                        className={classB}
                        title={`${h.cardB.power} (${h.cardB.subpower})`}
                    >
                        <img alt="" src={h.cardB.imageUrl} />
                    </span>
                </li>
            );
        });
        const winnerGame = scoreA > scoreB ? "A" : "B";
        const classHistoryScore =
            winnerGame === "A"
                ? "history-score history-a"
                : "history-score history-b";
        return (
            <div className={`history ${toggleHistory ? "open" : ""}`}>
                <button onClick={() => setToggleHistory(!toggleHistory)}>
                    <FontAwesomeIcon size="xs" icon={faClockRotateLeft} />
                </button>
                <ul>
                    <li className="history-players">
                        <span>
                            <img alt="" src={user.photo} />
                        </span>
                        <span>
                            <img alt="" src={playerB.photo} />
                        </span>
                        <span className={classHistoryScore}>
                            <b>{scoreA}</b>
                            <b>{scoreB}</b>
                        </span>
                        <span className="history-players-name">
                            <b>{user.pseudo}</b>
                            <b>{playerB.pseudo}</b>
                        </span>
                    </li>
                    {historyBattles}
                </ul>
            </div>
        );
    };

    return (
        <div className={classBataillePage}>
            {renderHistory()}
            <div className="player player-b player-bot">
                <FontAwesomeIcon size="xl" icon={faCrown} className="crown" />
                <img alt="" src={playerB.photo} />
                <span className="player-name">{playerB.pseudo}</span>
                <span className="player-score">{scoreB}</span>
            </div>
            <div className={`scene ${fighting || isMoving ? "fighting" : ""}`}>
                <div
                    className={`hand hand-b hand-${handB.length}`}
                    ref={handBRef}
                >
                    {handB}
                </div>
                <div
                    className={`battlefield ${
                        !battleFieldA || !battleFieldB ? "no-battle" : ""
                    }`}
                >
                    <div
                        ref={battleARef}
                        className="battlefield-card battlefield-a"
                    >
                        {battleFieldA}
                    </div>
                    <div
                        ref={battleBRef}
                        className="battlefield-card battlefield-b"
                    >
                        {battleFieldB}
                    </div>
                </div>
                <div className={`hand hand-a hand-${handA.length}`}>
                    {handA}
                </div>
            </div>
            <div className="player player-a">
                <FontAwesomeIcon size="xl" icon={faCrown} className="crown" />
                <img alt="" src={user.photo} />
                <span className="player-name">{user.pseudo}</span>
                <span className="player-score">{scoreA}</span>
                <span className="player-reward">
                    + <FontAwesomeIcon size="xs" icon={faCoins} /> {moneyGain}
                </span>
            </div>
            <Link to="/bataille" reloadDocument className="btn btn-restart">
                Recommencer une bataille
            </Link>
            <ModalLeave />
        </div>
    );
};

export default Bataille;
