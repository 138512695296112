import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    getDoc,
    getDocs,
    serverTimestamp,
    setDoc,
} from "firebase/firestore";
import { createContext, useContext, useEffect, useReducer } from "react";
import { db } from "../firebase";
import { useApplication } from "./ApplicationContext";

function cardsReducer(state, action) {
    if (action.type === "FETCH_CARDS") {
        return {
            ...state,
            cards: action.payload,
        };
    }
    if (action.type === "LOAD_CARDS") {
        return {
            ...state,
            cards: action.payload,
        };
    }
    if (action.type === "ORDERED_CARDS") {
        return {
            ...state,
            orderDirection: "desc",
            cards: action.payload,
        };
    }

    if (action.type === "REVERSED_CARDS") {
        return {
            ...state,
            cards: action.payload.cards,
            orderDirection: action.payload.direction,
        };
    }
    if (action.type === "ADD_CARD") {
        return {
            ...state,
            cards: [action.payload, ...state.cards],
        };
    }
    if (action.type === "DELETE_CARD") {
        return {
            ...state,
            cards: state.cards.filter((c) => c.id !== action.payload),
        };
    }
    if (action.type === "UPDATE_CARD") {
        return {
            ...state,
            cards: state.cards.map((c) => {
                if (c.id === action.payload.id) {
                    return action.payload;
                }
                return c;
            }),
        };
    }
    return state;
}

const CardsContext = createContext({
    cards: [],
});

export function CardsContextProvider({ children }) {
    const { application, updateApplication } = useApplication();
    const getCardsFromStorage = () => {
        const cardsStorage = localStorage.getItem("cards");
        if (cardsStorage) {
            const cards = JSON.parse(cardsStorage).cards;
            if (cards) {
                return cards;
            }
        }
        return null;
    };

    const [state, dispatch] = useReducer(cardsReducer, {
        orderDirection: "desc",
        cards: getCardsFromStorage(),
    });

    useEffect(() => {
        const cardsStorage = localStorage.getItem("cards");
        if (cardsStorage && application.lastUpdated) {
            const lastUpdatedCards = JSON.parse(cardsStorage).lastUpdated;
            if (lastUpdatedCards < application.lastUpdated.seconds) {
                getCards();
            }
        }
    }, [application]);

    const getCards = () => {
        const cards = [];
        getDocs(collection(db, "cards")).then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                const data = {
                    id: doc.id,
                    ...doc.data(),
                };
                cards.push(data);
            });
            dispatch({ type: "FETCH_CARDS", payload: cards });

            const cardsStorage = {
                cards: cards,
                lastUpdated: Math.round(new Date().getTime() / 1000),
            };
            localStorage.setItem("cards", JSON.stringify(cardsStorage));
        });
    };

    useEffect(() => {
        if (!state.cards) {
            getCards();
        }
    }, [state.cards]);

    const ordered = (order) => {
        let cards = [...state.cards];
        switch (order) {
            case "power":
                cards = cards.sort((a, b) => a.power - b.power);
                break;
            case "rarity":
                cards = cards.sort((a, b) => a.rarity - b.rarity);
                break;
            case "timestamp":
                cards = cards.sort((a, b) => {
                    return a.timeStamp.seconds - b.timeStamp.seconds;
                });
                break;
            case "family":
                cards = cards.sort((a, b) => {
                    if (a.family < b.family) {
                        return -1;
                    }
                    if (a.family > b.family) {
                        return 1;
                    }
                    return 0;
                });
                break;
            default:
                console.log(`${order} n'existe pas !`);
                break;
        }

        dispatch({ type: "ORDERED_CARDS", payload: cards });
    };

    const reversed = (direction) => {
        if (direction === state.direction) return;
        const cards = [...state.cards].reverse();
        dispatch({ type: "REVERSED_CARDS", payload: { cards, direction } });
    };

    const addCard = async (card) => {
        const cardData = {
            ...card,
            timeStamp: serverTimestamp(),
        };
        const docRef = await addDoc(collection(db, "cards"), cardData); // ajoute
        const newCard = await getDoc(docRef); // puis on va le chercher pour avoir le timestamp
        dispatch({
            type: "ADD_CARD",
            payload: { ...newCard.data(), id: newCard.id },
        });
        updateApplication();
    };

    const deleteCard = async (idCard) => {
        await deleteDoc(doc(db, "cards", idCard));
        dispatch({ type: "DELETE_CARD", payload: idCard });
        updateApplication();
    };

    const updateCard = async (card) => {
        await setDoc(doc(db, "cards", card.id), card);
        dispatch({ type: "UPDATE_CARD", payload: card });
        updateApplication();
    };

    return (
        <CardsContext.Provider
            value={{
                cards: state.cards,
                orderDirection: state.orderDirection,
                ordered,
                reversed,
                addCard,
                deleteCard,
                updateCard,
            }}
        >
            {children}
        </CardsContext.Provider>
    );
}

export function useCards() {
    return useContext(CardsContext);
}

export const OrderedCards = () => {
    const { ordered, orderDirection, reversed } = useCards();

    const handleSelect = (e) => {
        const value = e.target.value;
        value !== "" && ordered(value);
    };

    const handleOrder = (direction) => {
        reversed(direction);
    };

    return (
        <div className="filter-cards">
            <select onChange={handleSelect}>
                <option value="">Ordonner par...</option>
                <option value="timestamp">Date d'ajout</option>
                <option value="power">Puissance</option>
                <option value="rarity">Rareté</option>
                <option value="family">Famille</option>
            </select>
            <button
                onClick={() => handleOrder("desc")}
                className={orderDirection === "desc" ? "active" : null}
            >
                ↓
            </button>
            <button
                onClick={() => handleOrder("asc")}
                className={orderDirection === "asc" ? "active" : null}
            >
                ↑
            </button>
        </div>
    );
};
