export const UserReducer = (state, action) => {
    switch (action.type) {
        case "LOAD_USER": {
            return {
                ...state,
                photo: action.payload.photo,
                packs: action.payload.packs,
                pseudo: action.payload.pseudo,
                money: action.payload.money,
                cards: action.payload.cards,
                uid: action.payload.uid,
                deck: action.payload.deck,
                wins: action.payload.wins,
                fights: action.payload.fights,
                role: action.payload.role,
            }
        }
        case "SET_PHOTO": {
            return {
                ...state,
                photo: action.payload
            }
        }
        case "BUY_PACK": {
            return {
                ...state,
                money: state.money - action.payload.cost,
                packs: [...state.packs, action.payload.pack]
            }
        }
        case "GAIN_MONEY": {
            return {
                ...state,
                money: state.money + action.payload,
            }
        }
        case "REMOVE_PACK": {
            return {
                ...state,
                packs: action.payload
            }
        }
        case "ADD_CARDS": {
            return {
                ...state,
                cards: [...state.cards, ...action.payload]
            }
        }
        case "SET_DECK": {
            return {
                ...state,
                deck: action.payload
            }
        }
        case "SET_PSEUDO": {
            return {
                ...state,
                pseudo: action.payload
            }
        }
        case "INCREMENT_FIGHT": {
            return {
                ...state,
                fights: state.fights + action.payload
            }
        }
        case "INCREMENT_WIN": {
            return {
                ...state,
                wins: state.wins + action.payload
            }
        }
        case "LOGOUT": {
            return {
                state: action.payload
            }
        }
        default:
            return state
    }
}