import { useFamilies } from "../../hooks/useFamilies"
import {useNotificationDispatch} from "../../hooks/useNotification"
import "./ModalAddFamily.scss"

const ModalAddFamily = (props) => {

    const closeModal = () => props.refModalWrap.current.style.display = 'none'

    const { addFamily } = useFamilies()
    const notification = useNotificationDispatch()
    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const data = {
            name: formData.get('name'),
            abbreviation: formData.get('abbreviation')
        }
        addFamily(data);
        notification.add(`Nouvelle famille ajoutée : ${formData.get('name')}`)
        closeModal();
        e.target.reset()
    }

    return (<div className="modal">
        <button className="close" onClick={closeModal}>✕</button>
        <div className="modal-content">
            <h1>Ajouter une famille</h1>
            <div className="modal-body">
                <form onSubmit={handleSubmit}>
                    <p className="form-row">
                        <input type="text" name="name" placeholder="Nom" required />
                    </p>
                    <p className="form-row">
                        <input required type="text" name="abbreviation" placeholder="Abbréviation" />
                    </p>
                    <button type="submit">Envoyer</button>
                </form>
            </div>
        </div>
    </div>)
}

export default ModalAddFamily