import { createContext, useContext, useEffect, useReducer } from "react";
import {
    collection,
    getDocs,
    deleteDoc,
    doc,
    addDoc,
    serverTimestamp,
    query,
    setDoc,
} from "firebase/firestore";
import { db } from "../firebase";
import { useApplication } from "./ApplicationContext";

function familiesReducer(state, action) {
    if (action.type === "FETCH_FAMILIES") {
        return {
            ...state,
            families: action.payload,
        };
    }

    if (action.type === "DELETE_FAMILY") {
        return {
            ...state,
            families: state.families.filter((f) => f.id !== action.payload),
        };
    }

    if (action.type === "ADD_FAMILY") {
        return {
            ...state,
            families: [...state.families, action.payload],
        };
    }

    if (action.type === "UPDATE_FAMILY") {
        return {
            ...state,
            families: state.families.map((f) => {
                if (f.id === action.payload.id) {
                    return action.payload;
                }
                return f;
            }),
        };
    }

    return state;
}

export const FamiliesContext = createContext({
    families: [],
});

export function FamiliesContextProvider({ children }) {
    const { application, updateApplication } = useApplication();

    const getFamiliesFromStorage = () => {
        const familiesStorage = localStorage.getItem("families");
        if (familiesStorage) {
            const families = JSON.parse(familiesStorage).families;
            if (families) {
                return families;
            }
        }
        return null;
    };

    const [state, dispatch] = useReducer(familiesReducer, {
        families: getFamiliesFromStorage() || null,
    });

    const getFamilies = () => {
        const families = [];
        const ref = collection(db, "families");
        const q = query(ref);
        getDocs(q).then((snap) => {
            snap.forEach((doc) => {
                const data = {
                    ...doc.data(),
                    id: doc.id,
                };
                families.push(data);
            });
            dispatch({ type: "FETCH_FAMILIES", payload: families });

            const familiesStorage = {
                families: families,
                lastUpdated: Math.round(new Date().getTime() / 1000),
            };
            localStorage.setItem("families", JSON.stringify(familiesStorage));
        });
    };

    useEffect(() => {
        if (!state.families) {
            getFamilies();
        }
    }, [state.families]);

    useEffect(() => {
        const familiesStorage = localStorage.getItem("families");
        if (familiesStorage && application.lastUpdated) {
            const lastUpdatedfamilies = JSON.parse(familiesStorage).lastUpdated;
            if (lastUpdatedfamilies < application.lastUpdated.seconds) {
                getFamilies();
            }
        }
    }, [application]);

    const deleteFamily = async (id) => {
        await deleteDoc(doc(db, "families", id));
        dispatch({ type: "DELETE_FAMILY", payload: id });
        updateApplication();
    };

    const addFamily = async (data) => {
        const dataNew = {
            ...data,
            timeStamp: serverTimestamp(),
        };
        const docRef = await addDoc(collection(db, "families"), dataNew);
        const family = {
            ...data,
            id: docRef.id,
        };
        dispatch({ type: "ADD_FAMILY", payload: family });
        updateApplication();
    };

    const updateFamily = async (data) => {
        await setDoc(doc(db, "families", data.id), data);
        dispatch({ type: "UPDATE_FAMILY", payload: data });
        updateApplication();
    };
    return (
        <FamiliesContext.Provider
            value={{
                families: state.families,
                deleteFamily,
                addFamily,
                updateFamily,
            }}
        >
            {children}
        </FamiliesContext.Provider>
    );
}

export function useFamilies() {
    return useContext(FamiliesContext);
}
