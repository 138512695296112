import { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../hooks/AuthContext"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserNinja } from "@fortawesome/free-solid-svg-icons"
import { useNotificationDispatch } from "../../hooks/useNotification"

const Login = () => {
    const [error, setError] = useState(false)
    const navigate = useNavigate()
    const notification = useNotificationDispatch()
    const { login, status } = useContext(AuthContext)
    
    useEffect(() => {
        if(status === 'authenticated') {
            navigate('/shop')
        }
    }, [status, navigate])

    const handleSubmit = e => {
        e.preventDefault()
        setError(false)
        const formData = new FormData(e.target);
        const email = (formData.get("email"))
        const password = (formData.get("password"))

        login(email, password)
            .catch(e => {
                notification.add('Mauvais identifiants !')
            })
    }

    return (<div className="login-page">
        <h1 className="page-title"><FontAwesomeIcon icon={faUserNinja} /> Connexion</h1>
        <form onSubmit={handleSubmit} className="form">
            <p className="form-row"><input placeholder="e-mail" type="text" name="email" required /></p>
            <p className="form-row"><input placeholder="mot de passe" type="password" name="password" required /></p>
            {error && <p className="form-row form-row-error">Mauvais identifiants !</p>}
            <p className="form-row"><button className="btn-general" type="submit">Me connecter</button></p>
        </form>
    </div>)
}

export default Login