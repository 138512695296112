import { Link, NavLink } from 'react-router-dom';
import "./HeaderNav.scss";
import { useContext, useEffect, useRef, useState } from 'react';
import ModalAddFamily from '../pages/familys/ModalAddFamily';
import { AuthContext } from '../hooks/AuthContext';
import { useUser } from '../hooks/useUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faStore, faLayerGroup, faChessKnight, faChess, faUserNinja, faIdCard } from '@fortawesome/free-solid-svg-icons';

function HeaderNav() {
    const [userMoney, setUserMoney] = useState(null)
    const refModalWrap = useRef()
    const refMoney = useRef()
    const openModal = (e) => {
        e.preventDefault()
        refModalWrap.current.style.display = 'block'
    }
    const { status } = useContext(AuthContext)
    const { user } = useUser()

    useEffect(() => {
        setUserMoney(user.money)
        const ref = refMoney.current
        if (ref) {

            ref.addEventListener('animationend', () => {
                ref.classList.remove('highlight')
            })
        }

        if (userMoney !== user.money && userMoney !== null && ref) {
            ref.classList.add('highlight')
        }

        return () => {
            if (ref) {
                ref.removeEventListener('animationend', () => {
                    ref.classList.remove('highlight')
                })
            }
        }
    }, [user.money, userMoney])

    const classNavbar = status === 'authenticated' ? 'navbar navbar-authenticated' : 'navbar'
    const defaultLink = status === 'authenticated' ? '/shop' : '/login'
    return (
        <>
            <nav className={classNavbar}>
                <h1><Link to={defaultLink}><span>♠</span></Link></h1>
                {status === 'authenticated' && <>
                    <NavLink className="navlink" to="/bataille"><FontAwesomeIcon icon={faChess} size="xs" /> Bataille</NavLink>
                    <NavLink className="navlink" to="/decks"><FontAwesomeIcon icon={faChessKnight} size="xs" /> Decks</NavLink>
                    <NavLink className="navlink" to="/packs"><FontAwesomeIcon icon={faLayerGroup} size="xs" /> Packs</NavLink>
                    <NavLink className="navlink" to="/shop"><FontAwesomeIcon icon={faStore} size="xs" /> Boutique</NavLink>
                    <NavLink className="navuser-wrap" to="/user">
                        <div className='navuser'>
                            {(user && user.photo) && <img className="photo" src={user.photo} alt="" />}
                            {(user && !user.photo) && <span className="photo no-photo"></span>}
                            {user && user.pseudo}
                        </div>
                        <span ref={refMoney} className='header-money navlink'><FontAwesomeIcon icon={faCoins} /> {userMoney}</span>
                    </NavLink>
                </>}
                {status === 'no-authenticated' && <NavLink className="navlink" to="/signin"><FontAwesomeIcon icon={faIdCard} size="xs" /> Inscription</NavLink>}
                {status === 'no-authenticated' && <NavLink className="navlink" to="/login"><FontAwesomeIcon icon={faUserNinja} size="xs" /> Connexion</NavLink>}

            </nav>
            {user.role === 'admin' ? <>
                <nav className='navbar navbar-admin'>
                    <NavLink className="navlink" to="/cards/list">☰ cartes</NavLink>
                    <NavLink className="navlink" to="/cards/new">+ carte</NavLink>
                    <NavLink className="navlink" to="/packs/new">+ pack</NavLink>
                    <NavLink className="navlink" to="/familys/new">Famille</NavLink>
                    <a href="#ModalAddFamily" className="btn-add-family" onClick={openModal}>Ajouter une famille</a>
                </nav>
                <div ref={refModalWrap} className="modal-wrap">
                    <ModalAddFamily refModalWrap={refModalWrap} />
                </div>
            </> : null}
        </>
    )
}

export default HeaderNav