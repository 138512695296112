import { useContext, useEffect, useState } from "react";
import "./Shop.scss";
import { useUser } from "../../hooks/useUser";
import Pack from "../../components/Pack";
import Card from "../../components/Card";
import { useCards } from "../../hooks/useCards";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faStar,
    faCoins,
    faStore,
    faEnvelopeOpen,
} from "@fortawesome/free-solid-svg-icons";
import { PackContext } from "../../hooks/PackContext";
import { Link } from "react-router-dom";

const Shop = () => {
    const [shops, setShops] = useState({});
    const [shopsAction, setShopsAction] = useState({});
    const [packsShop, setPackShop] = useState([]);
    const { user, shopPack } = useUser();
    const { cards } = useCards();
    const { packs } = useContext(PackContext);

    useEffect(() => {
        if (packsShop.length) return;
        setPackShop(packs.filter((p) => p.visibility === true));
    }, [packs, packsShop.length]);
    useEffect(() => {
        const ShopItem = (props) => {
            const cardsFeature = [];
            const cardsPack = props.pack.cards
                .sort((a, b) => b.rarity - a.rarity)
                .slice(0, 8);
            for (let j = 0; j < 2; j++) {
                for (let i = 0; i < 8; i++) {
                    const card = cards.find((c) => c.id === cardsPack[i].id);
                    cardsFeature.push(
                        <div key={`${i}-${j}`} className="card-feature">
                            <Card key={card.id} {...card} />
                        </div>
                    );
                }
            }

            return (
                <>
                    <Pack
                        key={props.pack.id}
                        {...props.pack}
                        classPack="solo"
                    />
                    <div className="item-shop-content">
                        <h1>
                            <FontAwesomeIcon icon={faStar} /> {props.pack.name}{" "}
                            <FontAwesomeIcon icon={faStar} />
                        </h1>
                        <div className="cards-features">
                            <div className="cards-slide">{cardsFeature}</div>
                        </div>
                        <h2>
                            {" "}
                            {props.pack.cards.length} cartes différentes à
                            découvrir{" "}
                        </h2>
                    </div>
                </>
            );
        };

        const shopsContent = {};
        packsShop
            .filter((p) => p.visibility === true)
            .forEach((p) => {
                shopsContent[p.id] = <ShopItem key={p.id} pack={p} />;
            });

        setShops(shopsContent);
    }, [packsShop, cards]);

    useEffect(() => {
        if (!user.packs) return;

        const handleShop = (packId, packPrice) => {
            if (user.money - packPrice >= 0) {
                shopPack(packId, packPrice);
            }
        };

        const _shopsAction = {};
        packsShop.forEach((p) => {
            const possession = user.packs.filter((up) => up === p.id).length;
            _shopsAction[p.id] = (
                <div className="shop-actions">
                    {possession > 0 && (
                        <Link
                            to={`/packs/open/${p.id}`}
                            className="btn-open-pack"
                        >
                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                            <b>{possession}</b>
                        </Link>
                    )}
                    <button
                        className="btn-general"
                        onClick={() => handleShop(p.id, p.price)}
                    >
                        {p.price} <FontAwesomeIcon icon={faCoins} />
                    </button>
                </div>
            );
        });
        setShopsAction(_shopsAction);
    }, [packsShop, user.packs, shopPack, user.money]);

    const renders = [];
    packsShop.forEach((pack) => {
        renders.push(
            <div key={`shop-pack-${pack.id}`} className="item-shop">
                {shops[pack.id]}
                {shopsAction[pack.id]}
            </div>
        );
    });
    return (
        <>
            <h1 className="page-title">
                <FontAwesomeIcon icon={faStore} /> Boutique
            </h1>
            <div className="list-shop">
                {renders}
                {!renders.length ? (
                    <div className="empty-section">Boutique vide</div>
                ) : null}
            </div>
        </>
    );
};

export default Shop;
