import { doc, onSnapshot, serverTimestamp, setDoc } from "firebase/firestore";
import { db } from "../firebase";

const { createContext, useState, useContext, useEffect } = require("react");

export const ApplicationContext = createContext();

export const ApplicationContextProvider = ({ children }) => {
    const [application, setApplication] = useState({});

    useEffect(() => {
        const unsub = onSnapshot(doc(db, "applications", "application"), (r) =>
            setApplication({
                lastUpdated: r.data().lastUpdated,
            })
        );
        return () => unsub();
    }, []);

    const updateApplication = () => {
        setDoc(doc(db, "applications", "application"), {
            lastUpdated: serverTimestamp(),
        });
    };

    return (
        <ApplicationContext.Provider value={{ application, updateApplication }}>
            {children}
        </ApplicationContext.Provider>
    );
};

export const useApplication = () => useContext(ApplicationContext);
