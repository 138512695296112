import { useState } from "react"

const useConfirm = (title, message) => {
    const [promise, setPromise] = useState(null)

    const confirm = () => new Promise((resolve, reject) => {
        setPromise({ resolve })
    })

    const handleClose = () => {
        setPromise(null);
    }

    const handleConfirm = () => {
        promise?.resolve(true)
        handleClose()
    }

    const handleCancel = () => {
        promise?.resolve(false);
        handleClose()
    }

    const ConfirmModal = () => (<div className={`confirm-modal ${promise !== null ? 'modal-open' : ''}`}>
        <div className="confirm-modal-box">
            <h1>{title}</h1>
            <p>{message}</p>
            <div className="buttons">
                <button onClick={handleConfirm}>Oui</button>
                <button onClick={handleCancel}>Annuler</button>
            </div>
        </div>
    </div>)

    return [ConfirmModal, confirm]
}

export default useConfirm