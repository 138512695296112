import { useEffect, useRef, useState } from "react"
import "./Chat.scss"
import { Timestamp, doc, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase";
import { useUser } from "../../hooks/useUser";
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faMinus } from '@fortawesome/free-solid-svg-icons'

const Chat = () => {
    const [messages, setMessages] = useState([])
    const [newMessage, setNewMessage] = useState(null)
    const [reduceChat, setReduceChat] = useState(true)
    const [hasNewMessage, setHasNewMessage] = useState(false)

    const { user } = useUser()
    const chatBox = useRef()

    useEffect(() => {
        if (newMessage === null) return;
        setMessages((prev) => [...prev, newMessage])
        setNewMessage(null)
        if(reduceChat) {
            setHasNewMessage(true)
        }
    }, [newMessage, reduceChat])

    useEffect(() => {
        chatBox.current && (chatBox.current.scrollTop = chatBox.current.scrollHeight)
    }, [messages])

    useEffect(() => {
        const isRecentMessage = (dateMessage) => {
            const dateNow = new Date().getTime()
            const dateDelta = 5 * 60 * 1000; // 5 minutes
            const isRecentMessage = (dateNow - dateMessage) < dateDelta
            return isRecentMessage
        }
        const unsub = onSnapshot(doc(db, "chats", "general"), doc => {
            const snapMessage = doc.data()
            const dateMessage = snapMessage.timestamp.seconds * 1000
            if(isRecentMessage(dateMessage)) {
                setNewMessage(doc.data())
            }
        })
        return () => unsub()
    }, [])


    const handleSubmit = e => {
        e.preventDefault()
        const formData = new FormData(e.target)
        const message = formData.get('message')
        if(!message.trim().length) return

        setDoc(doc(db, 'chats', 'general'), {
            message: message.substring(0, 1000),
            timestamp: Timestamp.now(),
            user: {
                pseudo: user.pseudo || null,
                photo: user.photo || null
            }
        })

        e.target.reset()
    }

    const displayMessages = []
    messages.length && messages.forEach(m => {
        const messageDate = new Date(m.timestamp.seconds * 1000)
        displayMessages.push(<div className="message" key={uuidv4()}>
            <span className="chat-photo">
                <span className="message-info">{m.user.pseudo} ({messageDate.getHours()}:{messageDate.getMinutes()})</span>
                {m.user.photo && <img src={m.user.photo} alt="" className="chat-photo" />}
            </span>
            <p>{m.message.trim()}</p>
        </div>)
    })

    const handleReduce = () => {
        setReduceChat(!reduceChat)
        setHasNewMessage(false)
    }

    let chatBoxClass = ['chat-box']
    reduceChat && (chatBoxClass += ' reduce-chat')
    hasNewMessage && (chatBoxClass += ' has-new-message')
    return (<>
        <div className={chatBoxClass}>
            <button className="chat-reduce-box" onClick={() => handleReduce()}><FontAwesomeIcon icon={faPaperPlane} /></button>
            <h1 className="chat-box-top">
                Chat
                <button onClick={() => handleReduce()}>
                    <FontAwesomeIcon icon={faMinus} />
                </button>
            </h1>
            <div className="chat-box-content" ref={chatBox}>
                {displayMessages}
            </div>
            <form onSubmit={handleSubmit} className="chat-box-bottom">
                <input autoComplete="off" type="text" name="message" placeholder="votre message" />
                <button type="submit"><FontAwesomeIcon icon={faPaperPlane} /></button>
            </form>
        </div>
    </>)
}

export default Chat