import { useEffect } from "react"
import { useUser } from "../../hooks/useUser"
import { useNavigate } from "react-router-dom"

const Logout = () => {
    const { logoutUser } = useUser()
    const navigate = useNavigate()
    useEffect(() => {
        const timer = setTimeout(() => {
            logoutUser().then(() => {
                navigate('/login')
                navigate(0)
            })
        }, 2000)

        return () => clearTimeout(timer)
    }, [logoutUser, navigate])
    return (<div className="bye">
        <h1>BYE BYE</h1>
        <img src="/bye.gif" alt="" />
    </div>)
}

export default Logout