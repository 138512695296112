import { createContext, useReducer } from "react";
import { notificationReducer } from "./notificationReducer";
import NotificationsContainer from "../components/NotificationsContainer";

export const NotificationContext = createContext(null)
export const NotificationDispatchContext = createContext(null)

const initialState = {
    notifications: []
}


export const NotificationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(notificationReducer, initialState);
    return <NotificationDispatchContext.Provider value={dispatch}>
        <NotificationContext.Provider value={state}>
            {children}
            <NotificationsContainer notifications={state.notifications} />
        </NotificationContext.Provider>
    </NotificationDispatchContext.Provider>
}