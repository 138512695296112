import "./New.scss";
import {
    Input,
    Select,
    InputNumber,
    InputImage,
    ButtonSubmit,
} from "../../components/Form";
import { useEffect, useState } from "react";
import { storage } from "../../firebase";
import {
    ref,
    uploadBytes,
    getDownloadURL,
    deleteObject,
} from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { useNotificationDispatch } from "../../hooks/useNotification";
import { useFamilies } from "../../hooks/useFamilies";
import { useCards } from "../../hooks/useCards";
import Compressor from "compressorjs";

function New(props) {
    const { addCard, deleteCard, updateCard, cards } = useCards();

    const [data, setData] = useState({
        name: "",
        attack: "",
        power: 1,
        family: "",
        imageUrl: null,
    });

    const [file, setFile] = useState();

    const notification = useNotificationDispatch();
    const navigate = useNavigate();
    // families
    const [familiesOpt, setFamiliesOpt] = useState([]);

    const { families } = useFamilies();
    const { idCard } = useParams();

    useEffect(() => {
        if (families.length && !familiesOpt.length) {
            const familiesOpt = [];
            families.forEach((d) => {
                familiesOpt.push({
                    value: d.id,
                    label: d.name,
                });
            });
            setFamiliesOpt(familiesOpt);
        }

        if (idCard && !data.id) {
            const card = cards.find((c) => c.id === idCard);
            if (!card) {
                notification.add("La carte a modifié n'existe pas");
                navigate("/cards/list");
            }
            setData(card);
        }
    }, [
        families,
        idCard,
        navigate,
        notification,
        cards,
        familiesOpt.length,
        data.id,
    ]);

    const handleInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setData({ ...data, [name]: value });
    };

    const handleFile = (e) => {
        const file = e.target.files[0];

        if (file.type === "image/gif") {
            setFile(file);
        } else {
            new Compressor(file, {
                quality: 0.9,
                height: 432,
                width: 348,
                resize: "cover",
                success(result) {
                    setFile(result);
                },
            });
        }
    };

    // form conf
    const formConf = {
        name: {
            label: "Nom",
            name: "name",
            value: data.name,
            onChange: handleInput,
        },
        attack: {
            label: "Attaque",
            name: "attack",
            value: data.attack,
            onChange: handleInput,
        },
        rarity: {
            label: "Rareté",
            name: "rarity",
            value: data.rarity,
            onChange: handleInput,
            options: [
                {
                    value: 1,
                    label: "Hero",
                },
                {
                    value: 2,
                    label: "Extrême",
                },
                {
                    value: 3,
                    label: "Sparking",
                },
                {
                    value: 4,
                    label: "Legend",
                },
                {
                    value: 5,
                    label: "Ultra",
                },
            ],
        },
        family: {
            label: "Famille",
            name: "family",
            value: data.family,
            onChange: handleInput,
            options: familiesOpt,
        },
        power: {
            label: "Puissance",
            name: "power",
            value: data.power,
            onChange: handleInput,
        },
        image: {
            label: "Image",
            name: "image",
            onChange: handleFile,
            currentImage: data.imageUrl,
        },
    };

    const addNewCard = (dataCardImage) => {
        const dataCard = {
            ...data,
            ...dataCardImage,
        };

        addCard(dataCard);
        notification.add("Nouvelle carte ajoutée !");
        navigate("/cards/list");
        //navigate(0)
    };

    const updateCurrentCard = async (dataCardImage) => {
        const dataCard = dataCardImage ? { ...data, ...dataCardImage } : data;
        updateCard(dataCard);
        notification.add("Carte modifiée !");
        navigate("/cards/list#" + idCard);
    };

    // uploadImage
    const uploadImage = () => {
        return new Promise((resolve, reject) => {
            if (!file) reject();
            try {
                const uploadingImage = new Promise((resolve, reject) => {
                    const imageRef = new Date().getTime() + "-bataille.jpg";
                    const storageRef = ref(storage, imageRef);
                    uploadBytes(storageRef, file).then((snapshot) => {
                        getDownloadURL(storageRef).then((imageUrl) =>
                            resolve({ imageUrl, imageRef })
                        );
                    });
                });

                uploadingImage.then((imageData) => {
                    const dataCardImage = {
                        imageUrl: imageData.imageUrl,
                        imageRef: imageData.imageRef,
                    };
                    resolve(dataCardImage);
                });
            } catch (error) {
                reject(error);
            }
        });
    };

    const deleteImage = () => {
        return new Promise(function (resolve, reject) {
            const imageRef = ref(storage, data.imageRef);
            getDownloadURL(imageRef)
                .then((oldImageUrl) => {
                    // supprime l'ancienne image
                    deleteObject(imageRef).then(() => {
                        notification.add(
                            "L'ancienne image a bien été supprimé"
                        );
                        resolve();
                    });
                })
                .catch((e) => {
                    console.log(e);
                    resolve();
                });
        });
    };

    // submit
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!props.updateMode) {
            uploadImage().then((dataCardImage) => addNewCard(dataCardImage));
        } else {
            // véfifie que l'image a changé
            if (file) {
                deleteImage().then(() => {
                    uploadImage().then((dataCardImage) =>
                        updateCurrentCard(dataCardImage)
                    );
                });
            } else {
                // update sans toucher à l'image
                updateCurrentCard();
            }
        }
    };

    const deleteCurrentCard = (e) => {
        e.preventDefault();
        deleteImage().then(async () => {
            deleteCard(idCard);
            notification.add("Carte supprimée !");
            navigate("/cards/list");
        });
    };

    return (
        <div>
            <h1 className="page-title">
                {props.updateMode ? "Modifier une carte" : "Ajouter une carte"}
            </h1>
            <form onSubmit={handleSubmit} className="form-new">
                <div className="form-column">
                    <InputImage {...formConf.image} />
                </div>
                <div className="form-column">
                    <Input {...formConf.name} />
                    <Input {...formConf.attack} />
                    <Select {...formConf.rarity} />
                </div>
                <div className="form-column">
                    <Select {...formConf.family} />
                    <InputNumber {...formConf.power} />
                    <ButtonSubmit />
                    {props.updateMode && (
                        <button
                            onClick={(e) => deleteCurrentCard(e)}
                            className="btn-delete-card"
                        >
                            Supprimer la carte
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
}

export default New;
