const AuthReducer = (state, action) => {
    switch(action.type) {
        case "SET_SESSION": 
            return {
                status: action.payload.status,
                uid: action.payload.uid,
                user: action.payload.user
            }
        case "LOGIN": {
            return {
                ...state,
                uid: action.payload
            }
        }
        case "LOGOUT": {
            return {
                ...state,
                uid: null,
                user: null
            }
        }
        case "SET_USER": {
            return {
                ...state,
                user: action.payload
            }
        }
        case "SET_PHOTO": {
            return {
                ...state,
                user: {
                    ...state.user,
                    photo: action.payload
                }
            }
        }
        case "BUY_PACK": {
            return {
                ...state,
                user: {
                    ...state.user,
                    money: state.user.money - action.payload.cost,
                    packs: [...state.user.packs, action.payload.pack]
                }
            }
        }
        default:
            return state
    }
}

export default AuthReducer