import { useEffect, useRef, useState } from "react"
import "./User.scss"
import { useCards } from "../../hooks/useCards"
import { useUser } from "../../hooks/useUser"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPencil, faUser, faChessKnight } from "@fortawesome/free-solid-svg-icons"
import Card from "../../components/Card"
import { Link, useNavigate } from "react-router-dom"
import { useNotificationDispatch } from "../../hooks/useNotification"

const User = () => {
    const [photoClass, setPhotoClass] = useState('my-photo-wrap')
    const { user, setPhoto, updatePseudo, cardWithSubpower, deleteUser } = useUser()
    const [pseudo, setPseudo] = useState('')
    const { cards } = useCards()
    const notification = useNotificationDispatch()
    const refPseudo = useRef()
    const photos = []
    const listPhotosCards = [...cards].sort((a, b) => 0.5 - Math.random()).slice(0, 3);
    listPhotosCards.forEach(c => {
        photos.push(<img onClick={() => setPhotoUser(c.imageUrl)} key={c.id} src={c.imageUrl} alt="" />)
    })

    const openPhotos = () => {
        const newPhotoClass = photoClass === 'my-photo-wrap' ? 'my-photo-wrap open' : 'my-photo-wrap'
        setPhotoClass(newPhotoClass)
    }

    const setPhotoUser = (imageUrl) => {
        imageUrl && setPhoto(imageUrl)
        setPhotoClass('my-photo-wrap')
    }

    const handlePseudo = e => {
        setPseudo(e.target.value)
    }
    const handleSubmitPseudo = e => {
        e.preventDefault()
        if (user.pseudo === pseudo) {
            refPseudo.current.focus()
        } else {
            updatePseudo(pseudo)
            refPseudo.current.blur()
        }
    }

    useEffect(() => setPseudo(user.pseudo || ''), [user.pseudo])

    const deck = []
    if (user.deck.length) {
        user.deck.forEach(cardId => {
            let card = cards.find(c => c.id === cardId)
            card = cardWithSubpower(card)
            deck.push(<li className="item" key={cardId}><Card {...card} /></li>)
        })
    }

    const navigate = useNavigate()
    const handleLogout = () => {
        navigate('/logout')
    }

    const handleDeleteAccount = e => {
        e.preventDefault();
        const formData = new FormData(e.target)
        const pass = formData.get('password')
        deleteUser(pass).then(() => {
            navigate('/logout')
        }).catch(() => notification.add('Mot de passe nécessaire pour supprimer votre compte.'))
    }

    return (<div>
        <h1 className="page-title"><FontAwesomeIcon icon={faUser} /> Mon compte</h1>
        <div className="user-page">
            <div className="col-left">

                <h2>Pseudo</h2>
                <form onSubmit={handleSubmitPseudo} className="form-pseudo">
                    <input ref={refPseudo} required value={pseudo} name="pseudo" onChange={handlePseudo} />
                    <button type="submit"><FontAwesomeIcon size="xs" icon={faPencil} /></button>
                </form>
                <h2>Statistiques</h2>
                <ul className="fights-stat">
                    <li><span>Combats</span> <span className="value">{user.fights}</span></li>
                    <li><span>Victoires</span> <span className="value">{user.wins}</span></li>
                    <li><span>Ratio</span> <span className="value">{user.fights > 0 ? (user.wins * 100 / user.fights).toFixed(1) : 0} %</span></li>
                </ul>
                <button className="btn-general" onClick={handleLogout}><FontAwesomeIcon size="xs" icon={faUser} /> Déconnexion</button>   
            </div>
            <div className="col-right">
                <h2>Mon avatar</h2>
                <div className={photoClass} onClick={openPhotos}>
                    {user.photo && <img className="my-photo" alt="" src={user.photo} />}
                    {!user.photo && <span className="my-photo no-photo"></span>}
                    <div className="list-photos">
                        <button onClick={() => setPhotoUser()} className="btn-close">✕</button>
                        {photos}
                    </div>
                </div>
                <h2>Mon deck</h2>
                {deck.length ? <ul className="user-deck">{deck}</ul> : null}
                <Link to="/decks" className="btn-general"><FontAwesomeIcon size="xs" icon={faChessKnight} /> Constituer un deck</Link>
                <h2>Supprimer mon compte</h2>
                <form onSubmit={handleDeleteAccount}>
                    <input type="password" name="password" placeholder="mot de passe" />
                    <button className="btn-general" type="submit"><FontAwesomeIcon size="xs" icon={faUser} /> Supprimer mon compte</button>
                </form>
            </div>
        </div>
    </div>)
}

export default User