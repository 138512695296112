import {
    Navigate,
    Outlet,
    RouterProvider,
    createBrowserRouter,
} from "react-router-dom";
import "./reset.scss";
import "./App.scss";
import HeaderNav from "./components/HeaderNav";
import List from "./pages/cards/List";
import New from "./pages/cards/New";
import NewFamily from "./pages/familys/NewFamily";
import NewPack from "./pages/packs/NewPack";
import Bataille from "./pages/bataille/Bataille";
import Login from "./pages/generals/Login";
import Home from "./pages/generals/Home";
import { useContext } from "react";
import { AuthContext } from "./hooks/AuthContext";
import Signin from "./pages/generals/Signin";
import User from "./pages/generals/User";
import Chat from "./pages/generals/Chat";
import Shop from "./pages/packs/Shop";
import UserPack from "./pages/packs/UserPack";
import OpenPack from "./pages/packs/OpenPack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import Decks from "./pages/generals/Decks";
import Logout from "./pages/generals/Logout";
import { FamiliesContextProvider } from "./hooks/useFamilies";
import { CardsContextProvider } from "./hooks/useCards";
import { PackContextProvider } from "./hooks/PackContext";
import { ApplicationContextProvider } from "./hooks/ApplicationContext";
function App() {
    const { status } = useContext(AuthContext);
    const RequireAuth = (props) => {
        const redirectToLogin = props.redirectToLogin === false ? false : true;
        if (status === "checking") {
            //console.log('checking')
            return (
                <div className="checking">
                    <span>
                        <FontAwesomeIcon icon={faSpinner} size="2xl" />
                    </span>
                </div>
            );
        }
        if (status === "authenticated") {
            //console.log('authenticated')
            return <>{props.children}</>;
        }
        if (status === "no-authenticated") {
            //console.log('no-authenticated')
            return redirectToLogin ? <Navigate to="/login" /> : null;
        }
    };

    const router = createBrowserRouter([
        {
            path: "/",
            element: (
                <>
                    <HeaderNav />
                    <div className="content">
                        <Outlet />
                    </div>
                    {status === "authenticated" ? <Chat /> : null}
                </>
            ),
            children: [
                {
                    path: "logout",
                    element: <Logout />,
                },
                {
                    path: "decks",
                    element: (
                        <RequireAuth>
                            <Decks />
                        </RequireAuth>
                    ),
                },
                {
                    path: "packs/open/:idPack",
                    element: (
                        <RequireAuth>
                            <OpenPack />
                        </RequireAuth>
                    ),
                },
                {
                    path: "packs",
                    element: (
                        <RequireAuth>
                            <UserPack />
                        </RequireAuth>
                    ),
                },
                {
                    path: "shop",
                    element: (
                        <RequireAuth>
                            <Shop />
                        </RequireAuth>
                    ),
                },
                {
                    path: "",
                    element: <Home />,
                },
                {
                    path: "login",
                    element: <Login />,
                },
                {
                    path: "signin",
                    element: <Signin />,
                },
                {
                    path: "cards/list",
                    element: (
                        <RequireAuth>
                            <List />
                        </RequireAuth>
                    ),
                },
                {
                    path: "cards/new",
                    element: (
                        <RequireAuth>
                            <New />
                        </RequireAuth>
                    ),
                },
                {
                    path: "cards/update/:idCard",
                    element: (
                        <RequireAuth>
                            <New updateMode={true} />
                        </RequireAuth>
                    ),
                },
                {
                    path: "familys/new",
                    element: (
                        <RequireAuth>
                            <NewFamily />
                        </RequireAuth>
                    ),
                },
                {
                    path: "packs/new",
                    element: (
                        <RequireAuth>
                            <NewPack />
                        </RequireAuth>
                    ),
                },
                {
                    path: "bataille",
                    element: (
                        <RequireAuth>
                            <Bataille />
                        </RequireAuth>
                    ),
                },
                {
                    path: "user",
                    element: (
                        <RequireAuth>
                            <User />
                        </RequireAuth>
                    ),
                },
            ],
        },
    ]);
    // console.log('uid', uid)
    // console.log('user', user)
    // console.log('status', status)
    return (
        <div className="wrap">
            {status === "authenticated" ? (
                <>
                    <ApplicationContextProvider>
                        <FamiliesContextProvider>
                            <CardsContextProvider>
                                <PackContextProvider>
                                    <RouterProvider
                                        router={router}
                                    ></RouterProvider>
                                </PackContextProvider>
                            </CardsContextProvider>
                        </FamiliesContextProvider>
                    </ApplicationContextProvider>
                </>
            ) : (
                <RouterProvider router={router}></RouterProvider>
            )}
        </div>
    );
}

export default App;
